import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

/** REINDERIZAÇÃO DE IMAGEM, PARA CONVERÇÃO **/
function getBase64Image(img: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const DesalfandegamentoPDF = async (dados: any) => {
    const today = new Date();
    const dia = today.getDate();
    const mes = today.toLocaleString('pt-BR', { month: 'long' });
    const ano = today.getFullYear();

    /** CONVERÇÃO DE IMAGEM PARA BASE64 **/
    const imageBlob = await fetch('/img/logo/InsigniaAngola.png').then(res => res.blob());
    const imageBase64 = await getBase64Image(imageBlob) as string;
    const imageConvert = await fetch('/img/logo/logoArmed.png').then(res => res.blob());
    const imageArmed = await getBase64Image(imageConvert) as string;
    const imageAgtpng = await fetch('/img/logo/AGT.png').then(res => res.blob());
    const agetpicture = await getBase64Image(imageAgtpng) as string;

        /** INICIALIZAÇÃO DE DOCUMENTOS **/
        const docDefinition: TDocumentDefinitions = {
            /** COFIGURAÇÃO INICIAL DE DOCUMENTOS **/
            pageSize: 'A4',
            pageMargins: [45, 10, 45, 100],

            /** CONTEUDO PRINCIPAL**/
            content: [
                // AAdiciona a imagem de logo
                {
                    image: imageBase64 as string,
                    width: 80,
                    height: 80,
                    alignment: 'center',
                },
                /** TITULO E SUBTITULO **/
                { text: 'REPÚBLICA DE ANGOLA', style: 'header', },
                { text: 'MINISTÉRIO DA SAÚDE', style: 'header', },
                { text: 'AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE', style: 'header', },
                { text: 'GABINETE DO DIRECTOR GERAL', style: 'header', decoration: 'underline', },
                { text: [`DECLARAÇÃO DESALFANDEGAMENTO N.º`, { text: `${dados?.numFaturaPro}`, bold: true, decoration: 'underline', }, `/ARMED/MINSA/${new Date(dados.dataDespacho).getFullYear()}`], style: 'header', margin: [0, 10, 0, 10] },
                // Texto da declaração
                {
                    text: [
                        `========== Para os devidos efeitos, afirma-se que no livro n.º 001/DLIF/ARMED/MINSA/2022 da Agência Reguladora de Medicamentos e Tecnologias de Saúde, nos termos da Lei n.º`,
                        { text: ` 15/05 `, bold: true },
                        `de 22 de Julho, Resolução n.º`, { text: ` 32/08 `, bold: true }, `de 1 de Setembro da Assembleia Nacional e dos Decretos Presidenciais n.º`, { text: ` 180/10 `, bold: true }, `de 18 de Agosto, n.º`, { text: ` 191/10 `, bold: true }, `de 01 de Setembro e n.º`, { text: ` 136/21 `, bold: true }, `de 1 de Junho, consta registada a Empresa `, { text: `${dados?.importador?.nome}`, bold: true }, `, sob a autorização de exercício farmacêutico n.º`, { text: `${dados?.importador?.numeroProcesso}`, bold: true }, `.`
                    ],
                    style: 'body',
                    margin: [0, 10, 0, 5],
                },
                // Texto adicional
                { text: [`========== Outrossim, declara-se não haver impedimento para que a mesma possa desalfandegar os medicamentos e/ou tecnologias de saúde constante da(s) factura(s) n.º `, { text: `${dados?.numFaturaPro}`, bold: true }, `, carta de porte n.º `, { text: `${dados?.numero}`, bold: true }, `, proveniente de `, { text: `${dados?.endereco}` , bold: true }, ` de origem `, { text: `${dados?.paisOrigem}`, bold: true }, ` e que se destina a comercialização [ `, { text: ` `},` ] doação [ `, { text: ` `},` ].`], style: 'body', margin: [0, 5, 0, 5], },
                {
                    text: [
                        `========== No entanto, observa-se que a inspeção da mercadoria será efectuada no terminal de cargas do Aeroporto Internacional 4 de Fevereiro, outro local:`, { text: `${dados?.declaracao.pontoEntrada}`, bold: true }, `.`
                    ], style: 'body', margin: [0, 5, 0, 5],
                },
                {
                    text: [
                        `==========`, { text: ` Atenção: `, bold: true }, `Contém produto controlado [ `, { text: ` `},`] termolábéis: 2º a 8ºC [ `, { text: ` `},`] até 15ºC [ `, { text: ` `},` ]. ==============================`
                    ], style: 'body', margin: [0, 5, 0, 5],
                },
                {
                    text: [
                        `========== Por ser verdade e me ter sido solicitada passa-se a presente declaração para que se lhe não imponha impedimento no desalfandegamento, que vai por mim assinada e autenticada com o carimbo a óleo em uso nesta Agência Reguladora. ========================================`
                    ], style: 'body', margin: [0, 5, 0, 5],
                },
                {
                    text: [
                        `========== Esta declaração não isenta eventuais penalizações caso se constate irregularidades no acto da vistoria ou inspeção. ==============================`
                    ], style: 'body', margin: [0, 5, 0, 5],
                },
                {
                    text: [
                        `================================ Válida por 30 dias ================================`
                    ], style: 'body', alignment: 'center', margin: [0, 5, 0, 5], bold: true, color: '#30406C',
                },
                // Assinatura
                {
                    text: [`GABINETE DO DIRECTOR GERAL DA AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE, EM LUANDA, AOS `, { text: `${dia}`, bold: true }, ` DE `, { text: `${mes.toUpperCase()}`, bold: true }, ` DE `, { text: `${ano}`, bold: true }, `.`],
                    alignment: 'left',
                    style: 'header',
                },
                {
                    text: 'A DIRECÇÃO',
                    alignment: 'center',
                    style: 'signature',
                    margin: [0, 10, 0, 10],
                },
                {
                    text: 'MARIA JÚLIA GABRIELA SIMÃO',
                    alignment: 'center',
                    style: 'signature',
                },
                {
                    text: 'Chefe de Departamento de LIF',
                    alignment: 'center',
                    style: 'signature',
                    bold: false,
                    margin: [0, 0, 0, 0],
                },
            ],
            // Adiciona o rodapé
            footer: () => { 
                return [
                    { text: '____________________________________________________________________________________________________', style: '', margin: [0, 0, 0, 0], bold: true, alignment: 'center' },
                    {
                        columns: [
                            {
                                stack: [
                                    { text: 'Rua Cmdt. Che Guevara nº 6/86º Bº Maculusso, Ingombota', style: '', margin: [0, 10, 0, 0], bold: true },
                                    { text: 'Tel.: +244 938 120 372', style: '', margin: [0, 0, 0, 0], bold: true },
                                    { text: 'Email: geral@armed.minsa.gov.ao LUANDA-ANGOLA', style: '', margin: [0, 0, 0, 0], bold: true },
                                ],
                                width: '70%',
                                fontSize: 9,
                                alignment: 'left'
                            },
                            {
                                width: '30%',
                               
                                stack: [
                                    {
                                        image: imageArmed as string,
                                        width: 150,
                                        height: 50,
                                        alignment: 'center' as 'center',
                                    },
                                ]
                            },
                        ],
                        alignment: 'center',
                        fontSize: 8,
                        margin: [50, 0, 50, 0],
                    }
                ]
            },

            images: {
                mySuperImage: `data:image/jpeg;base64,/${imageBase64}`,
                // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
                snow: '/img/logo/logoArmed.png',
                // is supported loading images via url with custom headers (minimal version: 0.2.5)
                strawberries: {
                    url: '/img/logo/logoArmed.png',
                    headers: {
                        myheader: '123',
                        myotherheader: 'abc',
                    }
                }
            } as Record<string, any>,

            /** ESTILOS DE DOCUMENTO **/
            styles: {
                header: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 0],
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 0],
                    decoration: 'underline',
                },
                declaration: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 0],
                    decoration: 'underline',
                },
                body: {
                    fontSize: 11,
                    alignment: 'justify',
                    margin: [0, 0, 0, 10],
                    lineHeight: 1.3,
                },
                signature: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 0],
                },
            },
            defaultStyle: {
                font: 'Roboto'
            }
        };

        pdfMake.createPdf(docDefinition).open();
    
};

export {
    DesalfandegamentoPDF,
}