
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import api from "../../../../../services/api";
import HeaderSession from "../../../../../utils/headerSession";
import Loading from "../../../../../load/loading";
import { useDispatch, useSelector } from 'react-redux';
// import SearshEstabelecimento from "../../../../../utils/searchEstabelecimento";
import FerramentaNavegacaoExportacao from "../../../../../utils/ferramentaNavegacaoExportacao";
import { useNavigate } from "react-router-dom";

import { isTablet } from 'react-device-detect';
import RegisterAccess from "../../../../../utils/registerAccess";
// import { SolicitacoesLicenciamentoExcel } from "../../../../exports/gerarelatorioexcel";
// import { SolicitacaoLicenciamentoPdf } from "../../../../exports/gerarelatoriopdf";
import ListaInspecoes from "../listaInspecoes";


export default function InspeccaoAgendadaArea() {
  const navigate = useNavigate()
  const user = useSelector(state => state.account.user);
  const [tipo, setTipo] = useState('')
  // const [estabelecimentos, setEstabelecimentos] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [order, setOrder] = useState('')
  const [loading, setLoading] = useState(false);
  const [configLista, setConfigLista] = useState('')
  const dispatch = useDispatch();
  const [inspeccoes, setInspeccoes] = useState('');

  useEffect(() => {

    if (isTablet || user?.funcao === 'Inspector') {
      navigate('/admin/minhas-inspeccoes')
    }


    if (user?.funcao === 'Inspector' || user?.funcao === 'Director Geral' || user?.funcao === 'Chefe de Departamento' || user?.funcao === 'CNP') {
    } else {
      dispatch({
        type: 'alert',
        payload: { alert: 'Acesso negado' },
      });
      navigate('/admin/dlif')
    }

    setLoading(true)

    // api.get(`/estabelecimentos?page=${page}&status=Inspecção Agendada&perPage=${perPage}&tipoId=${tipo}&sortBy=nome:${order}`)
    //   .then(response => {
    //     setConfigLista(response.data);
    //     setEstabelecimentos((prevItems) => [...prevItems, ...response.data.estabelecimentos]);


    //     setLoading(false)
    //   }).catch(err => { setLoading(false) })


    setInspeccoes('')
    api.get(`/inspeccaos?status=Não Realizada`)
      .then(response => {
        // console.log(response)
        setConfigLista(response.data);
        setInspeccoes(response.data.inspeccaos);
      }).catch(err => 'console.log(err)').
      finally(() => { setLoading(false) })

  }, [page, perPage, tipo, order, navigate, user?.funcao, dispatch]);



  return (
    <>
      <RegisterAccess page={'area de inspeccao agendada'} />

      <HeaderSession
        title='ESTABELECIMENTOS A SEREM INSPECCIONADOS'
        actions={true}
      // handleExportExcel={() => SolicitacoesLicenciamentoExcel(estabelecimentos)}
      // handleExportPDF={() => SolicitacaoLicenciamentoPdf(estabelecimentos)}
      />

      {/* Os HandlesExportes devem adaptados para esta lista. */}

      <Card style={{ padding: 20 }}>
        {/* <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={setTipo} placeholder={'Filtra por nome, número de entrada, data'} /> */}

        {inspeccoes &&
          <Card variant="outlined">

            <ListaInspecoes inspeccoes={inspeccoes} />

            <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={inspeccoes}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel} 
            />
          </Card>
        }

        {loading && <Loading />}
      </Card>
    </>
  )
}
