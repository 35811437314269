import { Container } from '@mui/material'
import HeaderSession from '../../../../utils/headerSession'
import ListaVazia from '../../../../utils/listaVazia'
// import TabelaGeral from '../../../../admin/gestMedicamentos/TabelaGeral'

export default function Autorizados() {
    return (
        <Container style={{ minHeight: '70vh', maxWidth: 800 }}>

            <HeaderSession title='MEDICAMENTOS' />
            <ListaVazia text={'SERVIÇO BREVIMENTE DISPONÍVEL'} />

            {/* <TabelaGeral /> */}
        </Container>
    )
}
