
import { useEffect } from 'react'
import { useState } from "react";
import api from '../services/api';
import LoadingShort from '../load/loadingShort';
import { Button, Tooltip } from '@mui/material';


export default function GetDoc({ id, title }) {
    const [load, setLoad] = useState(false)
    const [doc, setDoc] = useState('')

    useEffect(() => {
        setLoad(true);
        api.get(`/doc-upload/${id}`)
            .then(res => {
                setDoc(res.data.docUpload);
            })
            .catch((err) => {
                // console.log(err)
            }).finally(() => {
                setLoad(false);
            });
    }, [id])


    return (
        load ?
            <LoadingShort />
            :
            <Button size='small' style={{ background: '#85287e', margin: 10 }} >
                <a style={{ textDecoration: 'none', color: '#fff' }} href={doc?.anexo} target="_blank">
                    <Tooltip title={'Baixar modelo'}>
                        <img src='/img/icones/downloadicon.svg' alt="download" />
                        {title ?? ' Baixar Manual'}
                    </Tooltip>
                </a>
            </Button>
    )
}

