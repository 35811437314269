import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "./protectedRouter";
import TemplateAdmin from "../admin/template/templateAdmin";
import MenuDmts from "../admin/departamentos/dmts/menuDmts";
import ListaSolicitacoesImportacao from "../admin/departamentos/dmts/importacaoExportacao/listaSolicitacoesImportacao";
import GestSolicitacaoImportacaoExportacao from "../admin/departamentos/dmts/importacaoExportacao";
import ListaSolicitacoesDesalfandegamento from "../admin/departamentos/dmts/importacaoExportacao/listaSolicitacoesDesalfandegamento";

const dmts = ["DMTS", "CNP", "DG"];

const DmtsRoutes = ({ user }) => [
  <Route key="menu-dmts" path="/admin/dmts" element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath="/login"> <TemplateAdmin element={<MenuDmts />} /></ProtectedRoute>} />,
  <Route key="solicitacoes-importacao" path="/admin/dmts/solicitacoes-de-importacao" element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath="/login"> <TemplateAdmin element={<ListaSolicitacoesImportacao />} /> </ProtectedRoute>} />,

  <Route key="002"  element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ListaSolicitacoesImportacao />} /> </ProtectedRoute>} path="/admin/dmts/solicitacoes-de-importacao" />,
  <Route key="003"  element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestSolicitacaoImportacaoExportacao />} /> </ProtectedRoute>} path="/admin/dmts/gest/solicitacao-de-importacao/:id" />,
  <Route key="004"  element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ListaSolicitacoesDesalfandegamento />} /> </ProtectedRoute>} path="/admin/dmts/solicitacoes-de-desalfandegamento" />,
  <Route key="005"  element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestSolicitacaoImportacaoExportacao />} /> </ProtectedRoute>} path="/admin/dmts/gest/solicitacao-de-desalfandegamento/:id" />,
];

export default DmtsRoutes;
