
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from "@mui/material";
import CardServices from "../cardServices";
import HeaderSession from '../../../utils/headerSession';
import { useEffect, useState } from "react";
import api from "../../../services/api";
import RegisterAccess from '../../../utils/registerAccess';
// import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  containerCardesService: {
    // minHeight: '50vh',
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
  }
}))

interface MenuState {
  solicitacoesRenovacao: number;
  reclamacoes: number;
  estabelecimentosAnalise: number;
  chefeSeccao: number;
  chefeDepartamento: number;
  inspeccoes: number;
  analiseInspeccoes: number;
  directorGeral: number;
  estabelecimentosReprovados: number;
}


export default function MenuDlif() {
  const classes = useStyles();
  // const user = useSelector(state => state.account.user);

  const [menu, setMenu] = useState<MenuState>({
    solicitacoesRenovacao: 0,
    reclamacoes: 0,
    estabelecimentosAnalise: 0,
    chefeSeccao: 0,
    chefeDepartamento: 0,
    inspeccoes: 0,
    analiseInspeccoes: 0,
    directorGeral: 0,
    estabelecimentosReprovados: 0,
  })
  const [loadMenu, setLoadMenu] = useState<boolean>(true)


  useEffect(() => {
    api.get(`/menu/dlif`)
      .then(response => {
        setMenu(response.data);
      }).catch(error => {

      }).finally(() => setLoadMenu(false))

  }, [])

  return (
    <Container>
      <RegisterAccess page={'menu principal'} />
      <HeaderSession title={'MENU'} />

      <Box className={classes.containerCardesService}>
        <CardServices load={loadMenu} count={menu.estabelecimentosAnalise} icone={'/icones/Licenciamento.svg'} nomeService={'Solicitações de Licenciamento'} link={'/admin/recepcao/solicitacoes-de-licenciamento'} />
        <CardServices load={loadMenu} count={menu.estabelecimentosReprovados} icone={'/icones/solictacoesReprovadas.svg'} nomeService={'Solicitações Reprovadas'} link={'/admin/solicitacoes/reprovadas'} />
        <CardServices load={loadMenu} count={menu.reclamacoes} icone={'/icones/Reclamacoes.svg'} nomeService={'Reclamações'} link={'/admin/reclamacoes'} />
        <CardServices load={loadMenu} count={menu.solicitacoesRenovacao} icone={'/icones/renovacaoLicenciamento.svg'} nomeService={'Solicitações de Renovação'} link={'/admin/solicitacoes-de-renovacao'} />
        {/* <CardServices load={loadAgendas} count={agendas} icone={'/icones/Agenda2.svg'} nomeService={'Agenda'} link={'/admin/agendas-marcadas'} /> */}
        {/* <CardServices load={false} count={0} icone={'/icones/Desalfandegar.svg'} nomeService={'Importaçao & Exportação'} link={'/admin/solicitacoes-de-desalfandegamento'} /> */}
      </Box>

      <div>
        <h1 style={{ margin: 20, fontSize: 23 }}>
          ÁREAS
        </h1>
        <hr style={{ border: '1px solid #85287e' }} />
        <br />
        <Box className={classes.containerCardesService}>
          <CardServices load={loadMenu} count={menu.chefeSeccao} icone={'/icones/chefedaSeccao.svg'} nomeService={'Chefe de Secção'} link={'/admin/chefe-da-seccao/solicitacoes-de-licenciamento'} />
          <CardServices load={loadMenu} count={menu.chefeDepartamento} icone={'/icones/chefeDepartamento.svg'} nomeService={'Chefe de Departamento'} link={'/admin/chefe-do-departamento/solicitacoes-de-licenciamento'} />
          <CardServices load={loadMenu} count={menu.inspeccoes} icone={'/icones/inspeccaoAgendarVisita.svg'} nomeService={'Inspecção'} link={'/admin/inspeccao-agendada/solicitacoes-de-licenciamento'} />
          <CardServices load={loadMenu} count={menu.analiseInspeccoes} icone={'/icones/chefeDepartamento.svg'} nomeService={'Análise da inspecção'} link={'/admin/chefe-do-departamento/solicitacoes-de-licenciamento'} />
          <CardServices load={loadMenu} count={menu.directorGeral} icone={'/icones/diretoraGeral.svg'} nomeService={'Diretor(a) Geral'} link={'/admin/director-geral/solicitacoes-de-licenciamento'} />
        </Box>
      </div>
    </Container>
  )
}





