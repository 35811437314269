import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import './drawer.css'
import Alert from '@mui/material/Alert';
import { Tooltip } from '@mui/material';

interface FileUploaderProps {
    onFileSelect: (file: File | null, name: string) => void;
    inputName: string;
    inputTitle: string;
    inputDescription: string;
    maxSizeInMB?: number;
}

const InputDocUpload: React.FC<FileUploaderProps> = ({ onFileSelect, inputName, inputTitle, inputDescription, maxSizeInMB = 5 }) => {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);


    // const validateFile = (file: File): boolean => {
    //     if (file.type !== 'application/pdf' && inputName === 'listaItems') {
    //         setError('Por favor, selecione apenas arquivos PDF.');
    //         return false;
    //     }
    //     if (file.type !== '.xlsx' && inputName !== 'listaItems') {
    //         setError('Por favor, selecione apenas arquivos EXCEL.');
    //         return false;
    //     }

    //     const fileSizeInMB = file.size / (1024 * 1024);
    //     if (fileSizeInMB > maxSizeInMB) {
    //         setError(`O arquivo excede o tamanho máximo de ${maxSizeInMB}MB.`);
    //         return false;
    //     }

    //     setError(null);
    //     return true;
    // };

    // const validateFile = (file: File, inputName: string, setError: (message: string | null) => void, maxSizeInMB: number): boolean => {
    const validateFile = (file: File): boolean => {
        const isPDF = file.type === 'application/pdf';
        const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (inputName !== 'listaItems' && !isPDF) {
            setError('Por favor, selecione apenas arquivos PDF.');
            return false;
        }

        if (inputName === 'listaItems' && !isExcel) {
            setError('Por favor, selecione apenas arquivos EXCEL.');
            return false;
        }

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > maxSizeInMB) {
            setError(`O arquivo excede o tamanho máximo de ${maxSizeInMB}MB.`);
            return false;
        }

        setError(null);
        return true;
    };


    const handleDragOver = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
                onFileSelect(file, inputName);
            } else {
                // onFileSelect(null, '');
            }
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
                onFileSelect(file, inputName);
            } else {
                e.target.value = '';
                // onFileSelect(null, '');
            }

        }
    };
    return (
        <div className="ffile-uploader-container">
            <form
                // className="file-upload-form"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input
                    id={inputName}
                    type="file"
                    name='testeFile'
                    accept={inputName === 'listaItems' ? '.xls,.xlsx' : 'application/pdf'}
                    onChange={handleFileSelect}
                    className="file-upload-input"
                />

                <label
                    htmlFor={inputName}
                    className={`ffile-upload-label ${dragActive ? 'drag-active' : ''}`}
                >
                    <Tooltip title={inputDescription ?? inputTitle}>
                        <div className="upload-icon-container">

                            <p className="upload-text" style={{ display: selectedFile ? 'none' : 'flex', justifyItems: 'center', textAlign: 'left' }}>

                                {/* Arraste e solte aqui <b>   {inputTitle.length > 50 ? inputTitle.slice(0, 50) + '...' : inputTitle} </b> ou clique para carregar 
                            <br />
                            <small> (máx. {maxSizeInMB}mb)</small> */}
                                <span>
                                    {error ?
                                        <small className="error-message">{error}</small>
                                        :
                                        inputTitle
                                    }
                                </span>
                                <CloudUploadIcon style={{ color: '#fff', right: 0 }} fontSize="large" />
                            </p>
                            <p className="upload-text txt-name" style={{ display: !selectedFile ? 'none' : '' }} >
                                <Alert severity="success">
                                    {selectedFile?.name}
                                </Alert>
                            </p>
                        </div>
                    </Tooltip>
                </label>
            </form>
        </div>
    )
}


export default InputDocUpload
