/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import "@fontsource/roboto";
import "./relatorio.css";
//import { EstabelecimentoLicenciadoexcel, RlatorioGeralEspecificoExcel } from "./gerarelatorioexcel";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { selecionarMeses } from '../../../../../../services/meses';
import CNPApi from '../../../../../../services/apiCNP';
import api from '../../../../../../services/api';
import { Button } from '@mui/material';
import { PictureAsPdfOutlined } from '@mui/icons-material';
import { PdfRelatorioGeral } from './pdfRelatorioGeral';
import Loading from '../../../../../../load/loading';

import { Typography, Avatar, Card } from "@mui/material";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StatusEstabelecimento from '../../../../../../utils/statusEstabelecimento';
// import FerramentaNavegacaoExportacao from '../../../../../../utils/ferramentaNavegacaoExportacao';


interface Estabelecimento {
    municipio: any;
    directorTecnico: any;
    status: any;
    _id: string;
    logo: string;
    nome: string;
    tipo: {
        createdAt: string | number | Date; designacao: string
    };
    provincia: string;
}
interface Municipio {
    designacao: any;
    provincia: {
        designacao: string;
    };
}

export default function Geral() {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [esperaDados, setesperaDados] = useState(true);
    const [dados, setDados] = useState<string | undefined>('');
    const [dadosEstabelecimento, setDadosEstabelecimento] = useState<Estabelecimento[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dadosEstabelecimentoPaginated, setDadosEstabelecimentoPaginated] = useState<Estabelecimento[]>([]);
    const [dadosEstabelecimentoTotal, setDadosEstabelecimentoTotal] = useState<Estabelecimento[]>([]);
    const [dadosEstabelecimentoSubTotal, setDadosEstabelecimentoSubTotal] = useState<Estabelecimento[]>([]);
    const [meses, setMeses] = React.useState<string | null>(null);
    const [trimestre, setTrimestre] = React.useState<number | null>(null);
    const [semestre, setSemestre] = React.useState<number | null>(null);
    const [anos, setAnos] = useState<string | undefined>('');
    const [provincias, setProvincias] = useState<any[]>([]);
    const [tipoestatos, setEstatos] = useState<string[]>([]);
    const [municipiosFiltrados, setMunicipiosFiltrados] = useState<Municipio[]>([]);
    const [provinciaSelecionada, setProvinciaSelecionada] = useState('');
    const [selectedTipos, setSelectedTipos] = useState([]);
    const [totalItems, settotalItems] = useState(0);
    const [tipoEstabelecimento, setTipoEstabelecimento] = useState<any[]>([]);
    const [provincia, setProvincia] = useState<string>('');
    const [selectedMunicipiosData, setSelectedMunicipiosData] = useState<Municipio[]>([]);
    // const [open, setOpen] = React.useState(false);

    useEffect(() => {
        api.get(`/estabelecimentos?perPage=1`).then(response => {
            settotalItems(response?.data?.totalItems);
            //setesperaDados(false);
            //console.log(response?.data?.totalItems);
        }).catch(error => {
            // setesperaDados(false);
            //console.log(error)
        })
    }, []);

    useEffect(() => {
        api.get(`/estabelecimentos?perPage=${totalItems}&provincia=${provincia}&municipio=${provinciaSelecionada}&tipoId=${selectedTipos}&status=${dados}`)
            .then(response => {
                setDadosEstabelecimento(response.data.estabelecimentos || []);
                setesperaDados(false);
            })
            .catch(error => {
                setesperaDados(false);
                //  console.log(error);
            });
    }, [provincia, provinciaSelecionada, selectedTipos, dados, totalItems]);

    useEffect(() => {
        let filteredData = dadosEstabelecimento;
        if (anos) {
            filteredData = dadosEstabelecimento.filter(dado =>
                new Date(dado?.tipo?.createdAt).getFullYear() === parseInt(anos)
            );
        }
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDadosEstabelecimentoTotal(filteredData);
        setDadosEstabelecimentoPaginated(filteredData.slice(startIndex, endIndex));
    }, [anos, itemsPerPage, dadosEstabelecimento]);

    useEffect(() => {
        let filteredData = dadosEstabelecimentoTotal;
        if (meses) {
            filteredData = dadosEstabelecimentoTotal.filter(dado =>
                new Date(dado?.tipo?.createdAt).getMonth() === parseInt(meses)
            );
        }

        if (trimestre) {
            filteredData = filteredData.filter(dado => {
                const mes = new Date(dado?.tipo?.createdAt).getMonth() + 1;
                return Math.ceil(mes / 3) === trimestre;
            });
        }

        if (semestre) {
            filteredData = filteredData.filter(dado => {
                const mes = new Date(dado?.tipo?.createdAt).getMonth() + 1;
                return Math.ceil(mes / 6) === semestre;
            });
        }

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDadosEstabelecimentoSubTotal(filteredData);
        setDadosEstabelecimentoPaginated(filteredData.slice(startIndex, endIndex));

    }, [meses, trimestre, semestre, currentPage, itemsPerPage, dadosEstabelecimentoTotal]);

    useEffect(() => {
        api.get(`/tipo-estabelecimentos/`).then(response => {
            //console.log(response?.data?.tipoEstabelecimentos)
            setTipoEstabelecimento(response?.data?.tipoEstabelecimentos || []);
        }).catch(error => {
            // console.log(error)
        })
    }, []);

    useEffect(() => {
        CNPApi.get(`/provincias/`).then(response => {
            // setesperaDados(false);
            //console.log(response?.data?.provincias)
            setProvincias(response?.data?.provincias || []);
        }).catch(error => {
            //setesperaDados(false);
            //console.log(error)
        })
    }, []);

    useEffect(() => {
        CNPApi.get(`/municipios/`).then(response => {
            //console.log(response?.data?.municipios)
            setSelectedMunicipiosData(response?.data?.municipios || []);
        }).catch(error => {
            //console.log(error)
        })
    }, []);

    // const handleClick = () => {
    //     setOpen(!open);
    // };

    useEffect(() => {
        if (provincia) {
            const filteredMunicipios = selectedMunicipiosData.filter(municipio =>
                municipio?.provincia?.designacao === provincia
            );
            setMunicipiosFiltrados(filteredMunicipios as any);
        } else {
            setMunicipiosFiltrados([]);
        }
    }, [provincia, selectedMunicipiosData]);

    useEffect(() => {
        async function generatePdf() {
            if (dadosEstabelecimentoSubTotal) {
                const pdfDocGenerator = await PdfRelatorioGeral(dadosEstabelecimentoSubTotal);
                pdfDocGenerator.getDataUrl((dataUrl: any) => {
                    if (iframeRef.current) {
                        (iframeRef.current as HTMLIFrameElement).src = dataUrl;
                    } else {
                        //  console.error("Url invalida ou vasia.");
                    }
                });
            }
        }
        generatePdf();
    }, [dadosEstabelecimentoSubTotal]);



    const EscolheProvincias = (event: { target: { value: any } }) => {
        // console.log(event.target.value);
        setProvincia(event.target.value);
        setesperaDados(true);
        // setCurrentPage(1);
    };
    const EscolheMunicipios = (event: { target: { value: any } }) => {
        //console.log(event.target.value);

        setProvinciaSelecionada(event.target.value);
        //setesperaDados(true);
        // setCurrentPage(1);
    };
    const EscolheEstabelecimento = (event: { target: { value: any } }) => {
        // console.log(event.target.value);

        setSelectedTipos(event.target.value);
        //setesperaDados(true);
        //setCurrentPage(1);
    };
    const selecionaSTatus = (event: { target: { value: string } }) => {
        setDados(event.target.value);
        // setesperaDados(true);
        setCurrentPage(1);
        // setItemsPerPage(1);
    };

    const selecionaAno = (event: { target: { value: string } }) => {
        setAnos(event.target.value);
        //  setesperaDados(true);
        setCurrentPage(1);
    };
    // const selecionaMeses = (evento: any) => {
    //     setMeses(evento);
    //     setTrimestre(null);
    //     setSemestre(null);
    //     setCurrentPage(1);
    // };

    // const selecionaTrimestre = (evento: number) => {
    //     setTrimestre(evento);
    //     setMeses(null);
    //     setSemestre(null);
    //     setCurrentPage(1);
    // };
    // const selecionaSemestre = (evento: number) => {
    //     setSemestre(evento);
    //     setTrimestre(null);
    //     setMeses(null);
    //     setCurrentPage(1);
    // };

    const abrirPdfnoBrowser = async (dadosEstabelecimentoSubTotal: any) => {
        const pdfGerarAquivo = await PdfRelatorioGeral(dadosEstabelecimentoSubTotal);
        pdfGerarAquivo.open();
    };



    return (
        esperaDados ? <Loading text={"A carregar Dados"} focus={false} /> :
            < >
                <p>Filtros:</p>

                <div style={{ display: "flex", alignItems: 'center' }}>
                    <FormControl sx={{ width: 120, mr: 1 }}>
                        <InputLabel id="demo-simple-select-label">Provincias</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={provincia}
                            label="Provincia"
                            onChange={EscolheProvincias}
                        >
                            <MenuItem value={''}>Todas Provincias</MenuItem>
                            {
                                provincias.map((dadosprovincia, index) => (
                                    <MenuItem key={index} value={dadosprovincia?.designacao}>
                                        <div>{dadosprovincia?.designacao}</div>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: 120 }}>
                        <InputLabel id="municipio-select-label">Município</InputLabel>
                        <Select
                            disabled={!provincia}
                            labelId="municipio-select-label"
                            id="municipio-select"
                            value={provinciaSelecionada}
                            label="Município"
                            onChange={EscolheMunicipios}
                        >
                            <MenuItem value={''}>Todos Município</MenuItem>
                            {Array.isArray(municipiosFiltrados) && municipiosFiltrados.map((municipio, index) => (
                                <MenuItem key={index} value={municipio?.designacao}>
                                    {municipio?.designacao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 200 }}>
                        <InputLabel id="demo-simple-select-label">Tipo Estabelecimento</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedTipos}
                            label="Estabelecimento"
                            onChange={EscolheEstabelecimento}
                        >
                            <MenuItem value={''}>Todos Estabelecimentos</MenuItem>
                            {
                                Array.isArray(tipoEstabelecimento) && tipoEstabelecimento.map((meustabelecimento, index) => (
                                    <MenuItem key={index} value={meustabelecimento?._id}>
                                        {meustabelecimento?.designacao}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 100 }} >
                            <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={dados ?? undefined}
                                label="Data"
                                onChange={selecionaSTatus}
                                defaultValue={''}
                            >
                                <MenuItem value={''}>Geral</MenuItem>
                                {
                                    Array.from(new Set(tipoestatos.map((dado: any) => dado?.status)))
                                        .map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                }
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 100 }} >
                            <InputLabel id="demo-simple-select-label">Ano</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={anos ?? undefined}
                                label="Data"
                                onChange={selecionaAno}
                                defaultValue={''}
                            >
                                <MenuItem value={''}>Geral</MenuItem>
                                {
                                    Array.from(new Set(tipoestatos.map((dado: any) => new Date(dado?.tipo?.createdAt).getFullYear())))
                                        .map((ano, index) => (
                                            <MenuItem key={ano} value={ano}>{ano}</MenuItem>
                                        ))
                                }
                            </Select>
                        </FormControl>

                        {/* FUNCIONALIDADE POR MEXER */}
                        {/* <div className="group inline-block my-2">
                                <button
                                    className="outline-none focus:outline-none border px-3 py-1 bg-white rounded-sm flex items-center min-w-32 selecte"
                                >
                                    <span className="pr-1 font-semibold flex-1">Periodo</span>
                                    <span>
                                        <svg
                                            className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20">
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </span>
                                </button>
                                <ul className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32">
                                    <li className="rounded-sm px-3 py-1 hover:bg-gray-100"> <button onClick={() => { selecionaMeses(''); selecionaTrimestre(null as any); selecionaSemestre('' as any) }} value={''}>Anual</button></li>
                                    <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100">
                                        <button
                                            className="w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                            <span className="pr-1 flex-1">Mensal</span>
                                            <span className="mr-auto">
                                                <svg
                                                    className="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <ul
                                            className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32" >
                                            {selecionarMeses.map((mes, index) => (
                                                <li
                                                    className={`px-3 py-1 ${parseInt(meses as any) == (index + 1) ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`}
                                                    onClick={() => selecionaMeses(index < 9 ? '0' + (index + 1) : (index + 1))} key={index} >
                                                    <button>{mes}</button>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </li>
                                    <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100">
                                        <button
                                            className="w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                            <span className="pr-1 flex-1">Trimestral</span>
                                            <span className="mr-auto">
                                                <svg
                                                    className="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <ul className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32">
                                            {[1, 2, 3, 4].map((trim, index) => (
                                                <li
                                                    key={index}
                                                    className={`px-3 py-1 ${trimestre === trim ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`}
                                                    onClick={() => selecionaTrimestre(trim)}
                                                >
                                                    <button>{`${trim}º Trimestre`}</button>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100">
                                        <button
                                            className="w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                            <span className="pr-1 flex-1">Semestral</span>
                                            <span className="mr-auto">
                                                <svg
                                                    className="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                        <ul
                                            className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32">
                                            {[1, 2].map((sem, index) => (
                                                <li
                                                    key={index}
                                                    className={`px-3 py-1 ${semestre === sem ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`}
                                                    onClick={() => selecionaSemestre(sem)}
                                                >
                                                    <button>{`${sem}º Semestre`}</button>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}
                    </div>

                    <Button style={{ backgroundColor: '#85287e', padding: 15 }} variant="contained" onClick={() => abrirPdfnoBrowser(dadosEstabelecimentoSubTotal)} >
                        <PictureAsPdfOutlined sx={{ mr: 1 }} />
                        Gerar Pdf
                    </Button>
                </div>

                <div style={{ marginTop: 10 }}>
                    <Card variant="outlined" sx={{ width: '100%', overflow: 'hidden' }} >
                        <TableContainer >
                            <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Tipo</strong></Typography></TableCell>
                                        {/* <TableCell ><Typography noWrap> <strong>Nº de Entrada</strong></Typography></TableCell> */}
                                        <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                                        {/* <TableCell ><Typography noWrap> <strong>Solicitação</strong></Typography></TableCell> */}
                                        <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {dadosEstabelecimentoPaginated.map((estabelecimento) => (
                                        <TableRow key={estabelecimento?._id} title={estabelecimento?.nome}>

                                            <TableCell component="th" scope="row">
                                                <Avatar variant="square" src={estabelecimento?.logo} alt={estabelecimento?.nome} />
                                            </TableCell>

                                            <TableCell >{estabelecimento?.nome}</TableCell>
                                            <TableCell >{estabelecimento?.tipo?.designacao}</TableCell>
                                            {/* <TableCell >{estabelecimento?.numeroEntrada}</TableCell> */}
                                            {/* <TableCell >{estabelecimento?.numeroProcesso}</TableCell> */}
                                            <TableCell >{estabelecimento?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                                            <TableCell >{estabelecimento?.provincia}</TableCell>
                                            {/* <TableCell ><StatusEntrada status={estabelecimento?.tipoEntrada}/>  </TableCell> */}
                                            <TableCell >  <StatusEstabelecimento status={estabelecimento?.status} /></TableCell>
                                            {/* <TableCell > <Typography noWrap> {estabelecimento?.email}</Typography></TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {/* <FerramentaNavegacaoExportacao
                            configLista={configLista}
                            lista={estabelecimentos}
                            page={page}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            setOrder={setOrder}
                        // exportarExcel={exportarExcel}
                        /> */}

                        </TableContainer>
                    </Card>
                    <br />
                    <iframe
                        ref={iframeRef}
                        style={{ width: '100%', height: '90vh', border: '1px solid #ccc' }}
                        title="Gestor de Documentos"
                    />

                </div>
            </>

    )
}
