import { useEffect, useState } from "react";
import InformacaoAdmin from "./InformacaoAdmin";
import api from "../../services/api";
import Loading from "../../load/loading";
import { Container, DialogActions } from "@mui/material";
import HeaderSession from "../../utils/headerSession";
import RegisterAccess from "../../utils/registerAccess";
import { RelatorioAdministadoresPdf } from "../exports/gerarelatoriopdf";
import { RelatorioAdministadoresExcel } from "../exports/gerarelatorioexcel";

export default function ListaFuncionarios() {
    const [admins, setAdmins] = useState('')
    const [totalAdmins, setTotalAdmins] = useState('')

    useEffect(() => {
        api.get('/admins')
            .then(response => {
                setAdmins(response.data.Admins)
                setTotalAdmins(response.data.totalItems)
            }).catch(err => '')
    }, [])

    // console.log(admins)

    return (
        <Container>
            <RegisterAccess page={'lista de funcionarios'} />

            <HeaderSession
                title={'USUARIOS ADMINISTRADORES DO SISTEMA'}
                actions={true}
                handleExportExcel={() => RelatorioAdministadoresExcel(admins)}
                handleExportPDF={() => RelatorioAdministadoresPdf(admins)}
            />

            {totalAdmins &&
                <DialogActions>
                    Total: <b> {totalAdmins}</b>
                </DialogActions>
            }

            {admins ?
                admins.map((admin) => <InformacaoAdmin key={admin._id} admin={admin} />)
                :
                <Loading/>
            }
        </Container>
    )
}
