import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";
import Auth from "../admin/auth";
import DlifRoutes from "./dlifRoutes";
import DmtsRoutes from "./dmtsRoutes";
import CommonRoutes from "./commonRoutes";
import PublicRoutes from "./publicRoutes";
import Home from "../pages/home";


function App() {
    const user = localStorage.getItem("tipo"); // Substituir por Redux/contexto no futuro

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Auth>
                    <Suspense fallback={<div>Carregando...</div>}>
                        <Routes>
                            {/* Rotas publica */}
                           
                            {PublicRoutes({ user })}
                            {/* Rotas Comuns */}
                            {CommonRoutes({ user })}
                            {/* Rotas DLIF */}
                            {DlifRoutes({ user })}
                            {/* Rotas DMTS */}
                            {DmtsRoutes({ user })}

                            {/* ############################ */}
                            <Route key="default"  path="*" element={<Home />} />
                        </Routes>
                    </Suspense>
                </Auth>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
