import { Container } from '@mui/material'
import HeaderSession from '../../../../../utils/headerSession'
import RegisterAccess from '../../../../../utils/registerAccess'
import Geral from './componetes/geral'

export default function RelatorioIndex() {
  return (
    <Container style={{ maxWidth: '100vw', padding: 8 }}>
      <RegisterAccess page={'Relatório de Atividades Geral'} />
      <HeaderSession title={'RELATORIOS SOBRE LICENCIAMENTO DE ESTABELECIMENTOS'} />
      <br />
      {/* {component} */}
        <Geral />
    </Container>
  )
}
