
// Calendar.js
import { useEffect, useState } from 'react';
import './Calendar.css';
import { IconButton } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Mes from '../../../utils/mes';
import api from '../../../services/api';
import { useNavigate } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography, makeStyles } from "@material-ui/core";
import LoadingShort from '../../../load/loadingShort';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GetDoc from '../../../utils/getDoc';

const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#f0f0f0' }, cursor: 'pointer' }
}))

const Calendar = () => {
    const classes = useStyles()
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate()
    const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const dayNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const [agendas, setAgendas] = useState([])
    const [isMarked, setIsMarked] = useState([])
    const [loadAgenda, setLoadAgenda] = useState(false)
    const [horarios, setHorarios] = useState([])
    // const dataDisponivel = new Date().toISOString()
    // console.log(currentDate.getMonth())

    // Obter a data atual
    const [currentDate, setCurrentDate] = useState(new Date());

    // Obter o ano atual
    const currentYear = currentDate.getFullYear();

    // Obter o mês atual e somar 2 para o próximo mês
    let targetMonth = currentDate.getMonth() + 2;  // Meses começam do 0 (Janeiro = 0, Fevereiro = 1, etc.)
    let targetYear = currentYear;

    // Verificar se o mês ultrapassou dezembro (mes 11), e ajustar o ano e mês
    if (targetMonth > 11) {
        targetMonth = targetMonth - 12;  // Voltar para o começo do ano
        targetYear++;  // Incrementar o ano
    }

    useEffect(() => {
        setLoadAgenda(true)

        // API PARA BUSCAR DATA ACTUAL NO SERVIDOR PARA ENVIAR NA REQUISICAO DAS AGENDAS DISPONIVEIS APENAS ATE A DATA DE HOJE.
        api.get(`/get-date`)
            .then(response => {
                // console.log(response)
                api.get(`/agendas?status=Disponível&ano=${targetYear}&mes=${targetMonth}&perPage=200&dataDisponivel=${response.data.date}`)
                    .then(response => {
                        // console.log(response)
                        setAgendas(response.data.agendas);
                        setIsMarked(response.data.agendas);
                        setLoadAgenda(false)
                    }).catch(error => 'console.log(error)')

            }).catch(error => 'console.log(error)')

    }, [currentDate, horarios, targetYear, targetMonth])

    const getHorarios = (day) => {
        if (isMarked.find((e) => e.dia === day) && new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, day) >= new Date())
            setHorarios(agendas.filter(agenda => agenda.dia === day))
        // console.log(horarios)
    };


    const renderCalendar = () => {
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const firstDayIndex = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1).getDay();
        const days = [];
        for (let i = 1; i <= lastDay; i++) {
            days.push({
                dia: i,
                tipo: '',
                // Adicione outras propriedades conforme necessário
                // Exemplo: isWeekend: i % 6 === 0, // Marca os fins de semana
            });
        }
        const calendarDays = [];
        for (let i = 0; i < firstDayIndex; i++) {
            calendarDays.push(<div key={`empty-${i}`} className="empty-day"></div>);
        }

        days.forEach((dayObj) => {
            const day = dayObj.dia;
            const isDisabled = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, day) < new Date();
            const isMarkedVerify = isMarked.find((e) => e.dia === day);

            calendarDays.push(
                <div
                    key={day}
                    style={selectedDate === day ? { border: '2px solid red', transition: '.5s', transform: 'scale(1.2)' } : null}
                    className={
                        `day ${isDisabled ? 'disabled' : ''}
                        ${loadAgenda ? 'disabled' : ''}
                        ${!isMarkedVerify && 'disabled'}
                         ${isMarkedVerify?.tipo === 'Renovação' && 'renovacao'}
                         ${isMarkedVerify?.tipo === 'Licenciamento' && 'licenciamento'}
                         `}
                    onClick={() => { handleDayClick(day); getHorarios(day) }}
                >
                    {day}
                </div>
            );
        });

        return calendarDays;
    };

    const handleDayClick = (day) => {
        if (isMarked.find((e) => e.dia === day) && new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, day) >= new Date()) {
            setSelectedDate(day);
        } else {
            alert('Sem agenda disponivel para dia ' + day);
        }
    };

    return (
        <div>
            <Typography align="center" variant="h1" style={{ marginTop: 60, fontSize: 25, fontWeight: 'bold' }}>
                MARCAÇÃO DE AGENDA
                <br />
                {/* COMPONENTE PARA REQUISICAO DE UM DOCUMENTO ESPECIFICO */}
                <GetDoc id={'6734b792b049cf8a4b0b108f'} />
            </Typography>


            <br />
            {/* className={`${!selectedDate && 'calendarClose'}`} */}
            <div className={`calendar `} style={{ position: 'relative' }}>
                {/* <div  style={{ position: 'absolute', width: '50%', height: '50%' ,background:'#000'}}> */}

                <div className="header">
                    <IconButton onClick={() => { setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)); setSelectedDate(null) }}>
                        <NavigateBeforeIcon color='warning' />
                    </IconButton>

                    {/* <h2>{`${monthNames[currentDate.getMonth() + 1]} ${currentDate.getFullYear()}`}</h2> */}
                    <h2>{`${monthNames[(currentDate.getMonth() + 1) % 12]} ${currentDate.getFullYear() + (currentDate.getMonth() === 11 ? 1 : 0)}`}</h2>

                    <IconButton onClick={() => { setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)); setSelectedDate(null) }}>
                        <NavigateNextIcon color='warning' />
                    </IconButton>
                </div>

                <div className="weekdays">
                    {dayNames.map((day, index) => (
                        <div key={day}
                            className={
                                `weekday 
                         ${index === 1 && 'licenciamento'}
                        ${index === 3 && 'licenciamento'}
                            `}>
                            <span translate="no">{day}</span>
                        </div>
                    ))}
                </div>

                <div className="days">
                    {/* Renderização dos dias do calendário */}
                    {loadAgenda &&
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <LoadingShort />
                        </div>
                    }

                    {/* {(agendas.length === 0 &&  new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDay()) < new Date())&& */}
                    {(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()) < new Date()) && (
                        <div style={{ position: 'absolute', width: '100%', height: '100%', background: '#85287dae' }} >
                            <h1 align='center' style={{ marginTop: '8rem', color: '#fff' }}>
                                AGENDAMENTO COMPLETO
                                <br />
                                <CheckCircleOutlineIcon style={{ fontSize: 50 }} />
                            </h1>
                        </div>
                    )}


                    {renderCalendar()}

                </div>

                <div className="tags">
                    {/* <div>
                        <div className="tag-color1" />Renovação
                    </div> */}
                    <div>
                        <div>
                            <div className="tag-color2" />Licenciamento
                        </div>
                    </div>
                </div>

                {selectedDate !== null && (
                    <div className={`time-table ${selectedDate !== null ? 'show' : ''}`}>

                        {/* Tabela de horários */}
                        <h3>Horários disponíveis para {selectedDate} de <Mes mes={targetMonth} /></h3>
                        {/* INPUT PARA DAR FOCO NO COMPONENTE ASSIM QUE FOR EXIBIDO */}
                        <input autoFocus type="text" style={{ opacity: 0, height: 1 }} />

                        <TableContainer >
                            <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell ><Typography noWrap> <strong>Horário</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Tipo de agenda</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Status</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {horarios.map((horario) => (
                                        <TableRow key={horario?._id} className={classes.hover} onClick={() => navigate(`/licenciamento/${horario._id}`)}>

                                            <TableCell >
                                                {horario?.hora < 10 ? `0${horario?.hora}` : horario?.hora}:{horario?.minuto < 10 ? `0${horario?.minuto}` : horario?.minuto}</TableCell>
                                            <TableCell >{horario.tipo}</TableCell>
                                            <TableCell >{horario.status}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Calendar;
