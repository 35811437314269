import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

const GraficoHistorico = ({data, labels}) => {

  const [state, setState] = useState({

    series: [{
      data: data || []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 300
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val > 50 ? val + ' Interaçōes' : val; 
        },
      },
      xaxis: {
        categories: labels || [],
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
          },
          offsetX: 10
        }
      },
    },
  });


 useEffect(() => {
    setState(prevState => ({
      ...prevState,
      series: [{
        data: data || []
      }],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: labels || []
        }
      }
    }));
  }, [data, labels]);


  return (
    <div style={{
      width: '100%',
    }}>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="bar" height={1500} />
      </div>
      {/* <div id="html-dist"></div> */}
    </div>
  );
}

export default GraficoHistorico;