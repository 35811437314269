import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import { format } from "date-fns";
import HeaderSession from "../../utils/headerSession";

const MedicamentoDetalhado: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const medicamento = location.state;

  if (!medicamento) {
    return (
      <Box textAlign="center">
        <Typography variant="h6">Medicamento não encontrado.</Typography>
        <HeaderSession
          title="Detalhes do Medicamento"
        />
      </Box>
    );
  }

  return (
    <Container sx={{ display: "flex", flexDirection: "column"}}>
      <HeaderSession title="Detalhes do Medicamento" />
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          Detalhes do Medicamento
        </Typography>
        <Typography variant="body1">
          <strong>Nome Genérico:</strong> {medicamento.nomeGenerico}
        </Typography>
        <Typography variant="body1">
          <strong>Categoria:</strong>{" "}
          {medicamento.categoria?.nome || "Sem Categoria"}
        </Typography>
        <Typography variant="body1">
          <strong>Código Nacional:</strong> {medicamento.codNacional}
        </Typography>
        <Typography variant="body1">
          <strong>Código UNSPSC:</strong> {medicamento.codUNSPSC}
        </Typography>
        <Typography variant="body1">
          <strong>Apresentação:</strong> {medicamento.apresentacao}
        </Typography>
        <Typography variant="body1">
          <strong>Data de Criação:</strong>{" "}
          {format(new Date(medicamento.createdAt), "dd/MM/yyyy")}
        </Typography>

        {/* Áreas de Utilização do Medicamento */}
        <Typography variant="h6" sx={{ mt: 4 }}>
          Áreas de Utilização
        </Typography>
        {medicamento.utilizacaoMedicamento &&
        medicamento.utilizacaoMedicamento.length > 0 ? (
          <List>
            {medicamento.utilizacaoMedicamento.map(
              (area: { tipo: string; _id: string }, index: number) => (
                <ListItem key={area._id || index}>
                  <ListItemText primary={area.tipo} />
                </ListItem>
              )
            )}
          </List>
        ) : (
          <Typography variant="body1">
            Nenhuma área de utilização informada.
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default MedicamentoDetalhado;