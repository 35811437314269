import { useEffect, useState } from "react";
import { Card, Typography, makeStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import api from "../../services/api";
// import { useNavigate } from "react-router-dom";
import Loading from "../../load/loading";
import Mes from "../../utils/mes";
// import CreateAgenda from "./calendario/createAgenda";
import DeleteAgenda from "./calendario/deleteAgenda";
import MessageSuccess from '../../messages/messageSuccess';
import MessageError from '../../messages/messageError';
import SearshAgenda from '../../utils/searchAgenda';
import DateHora from "../../utils/dateHora";
import RegisterAccess from "../../utils/registerAccess";
import { RelatorioGestaoAgendaPdf } from "../exports/gerarelatoriopdf";
import { RelatorioAgendaExcel } from "../exports/gerarelatorioexcel";
import HeaderSession from "../../utils/headerSession";


const useStyles = makeStyles((theme) => ({
  hover: { '&:hover': { backgroundColor: '#d2d6ff' } }
}))

export default function AgendaDisponivel() {
  const classes = useStyles()
  // const navigate = useNavigate()
  const [messageSuccess, setMessageSuccess] = useState('')
  const [messageError, setMessageError] = useState('')
  const [agendas, setagendas] = useState('')
  const [filter, setFilter] = useState('')
  const [page,] = useState(1)
  const [forceUpdate, setForceUpdate] = useState('')


  useEffect(() => {
    api.get(`/agendas?page=${page}&perPage=100`)
      .then(response => {

        setagendas(response.data.agendas);
        setFilter(response.data.agendas);
      }).catch(err => '')
  }, [page, forceUpdate])

  const filtraAgenda = async (tipo) => {
    setagendas(filter.filter((agenda) => (
      agenda?.tipo?.toLowerCase().includes(tipo.toLowerCase())
    )))
  }

  return (
    <>
      <HeaderSession
        title='AGENDAS DISPONÍVEIS'
        actions={true}
        handleExportExcel={() => RelatorioAgendaExcel(agendas)}
        handleExportPDF={() => RelatorioGestaoAgendaPdf(agendas)}
      />

      <Card style={{ padding: 20, minHeight: '20vh', }}>
        <RegisterAccess page={'agenda disponivel'} />
        {/* {messageSuccess && <MessageSuccess message={messageSuccess} />}
        {messageError && <MessageError message={messageError} />} */}

        {/* FILTRO DE TIPOS DE AGENDAS */}
        <SearshAgenda functionFilter={filtraAgenda} placeholder={"Filtra pelo tipo.. "} />

        {agendas ?
          <Card variant="outlined">

            <TableContainer >
              <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                <TableHead >
                  <TableRow>
                    <TableCell ><Typography noWrap> <strong>Tipo de agenda</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Horário</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Dia</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Mês</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Ano</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Data para disponibilizar</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Apagar</strong></Typography></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {agendas.map((agenda) => (
                    // onClick={() => navigate(`/licenciamento/${agenda._id}`)}
                    <TableRow key={agenda._id} title={agenda?.nome} className={classes.hover} >
                      <TableCell >{agenda?.tipo}</TableCell>
                      <TableCell >{agenda?.hora < 10 && 0}{agenda.hora} : {agenda?.minuto < 10 && 0}{agenda.minuto}</TableCell>
                      <TableCell >{agenda?.dia}</TableCell>
                      <TableCell ><Mes mes={agenda?.mes} /></TableCell>
                      <TableCell >{agenda?.ano}</TableCell>
                      <TableCell >{agenda?.status}</TableCell>
                      <TableCell >
                        {
                          agenda?.dataDisponivel &&
                          <DateHora date={agenda?.dataDisponivel} />
                        }
                      </TableCell>
                      <TableCell >
                        <DeleteAgenda
                          agendaId={agenda._id}
                          setForceUpdate={setForceUpdate}
                          setMessageSuccess={setMessageSuccess}
                          setMessageError={setMessageError}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          :
          <Loading />
          // <ListaVazia text={'NENHUMA AGENDA CRIADA'} />
        }
      </Card>
    </>
  )
}