import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
} from "@mui/material";
import { Medicamento } from "./types/typesListaMedicamentos";
import HeaderSession from "../../utils/headerSession";
import Loading from "../../load/loading";

interface Categoria {
  _id: string;
  nome: string;
  pai: string | null; // ID da categoria pai (se houver)
}

const TabelaGeral: React.FC = () => {
  const [medicamentos, setMedicamentos] = useState<Medicamento[]>([]);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/medicamentos")
      .then((resMedicamentos) => {
        setMedicamentos(resMedicamentos.data.medicamentos);
        return api.get("/categorias");
      })
      .then((resCategorias) => {
        setCategorias(resCategorias.data.categorias);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados:", error);
        setIsLoading(false);
      });
  }, []);

  const medicamentosFiltrados = medicamentos.filter((medicamento) => {
    const categoria = categorias.find(
      (cat) => cat._id === medicamento.categoria._id
    );
    const categoriaNome = categoria?.nome || "";
    return (
      medicamento.nomeGenerico
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      categoriaNome.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const categoriasMapeadas = categorias.reduce<Record<string, Categoria[]>>(
    (map, categoria) => {
      if (categoria.pai) {
        map[categoria.pai] = [...(map[categoria.pai] || []), categoria];
      } else {
        map[categoria._id] = [...(map[categoria._id] || [])];
      }
      return map;
    },
    {}
  );

  const gerarNumeracaoHierarquica = (
    categorias: Categoria[],
    prefixo: string = "",
    contador: Record<string, number> = {}
  ): Record<string, string> => {
    const numeracao: Record<string, string> = {};
    categorias.forEach((categoria, index) => {
      const numAtual = `${prefixo}${index + 1}`;
      numeracao[categoria._id] = numAtual;
      const subcategorias = categoriasMapeadas[categoria._id] || [];
      Object.assign(
        numeracao,
        gerarNumeracaoHierarquica(subcategorias, `${numAtual}.`, contador)
      );
    });
    return numeracao;
  };

  const numeracaoCategorias = gerarNumeracaoHierarquica(
    categorias.filter((cat) => !cat.pai)
  );

  const handleRowClick = (medicamento: Medicamento) => {
    navigate(`/medicamento/${medicamento._id}`, { state: medicamento });
  };

  const handleAdicionarMedicamento = () => {
    navigate("/adicionar-medicamento"); // Rota para adicionar medicamento
  };

  return (
    <Container>
      <HeaderSession title="TABELA DE MEDICAMENTOS(LNME)" />
      <Box sx={{ background: "white", padding: "1rem" }}>
        <Box marginBottom={2} gap={2} display="flex" justifyContent="space-between">
          <TextField
            label="Buscar por Nome ou Categoria"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
          <button
            color="primary"
            onClick={handleAdicionarMedicamento}
            style={{
              padding: "12px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              color: " white",
              backgroundColor: " #85287e",
            }}
          >
            Adicionar Medicamento
          </button>
        </Box>

        {isLoading ? (
          <Loading text={""} focus={false} />
        ) : (
          <TableContainer sx={{ border: "1px black" }} component={Paper}>
            <Table sx={{ border: "1px", borderColor: "black" }}>
              <TableHead sx={{ backgroundColor: "#dcdede" }}>
                <TableRow>
                  <TableCell>Código Nacional</TableCell>
                  <TableCell>Código UNSPSC</TableCell>
                  <TableCell>Apresentação</TableCell>
                  <TableCell>Nome Genérico</TableCell>
                  <TableCell>Data de Criação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ border: "1px", borderColor: "black" }}>
                {categorias
                  .filter((cat) => !cat.pai)
                  .map((categoria) => (
                    <React.Fragment key={categoria._id}>
                      {/* Categoria Pai */}
                      <TableRow>
                        <TableCell colSpan={5} style={{ fontWeight: "bold" }}>
                          {`${numeracaoCategorias[categoria._id]} ${
                            categoria.nome
                          }`}
                        </TableCell>
                      </TableRow>

                      {/* Subcategorias e Medicamentos */}
                      {(categoriasMapeadas[categoria._id] || []).map(
                        (subcat) => (
                          <React.Fragment key={subcat._id}>
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                style={{ paddingLeft: 20 }}
                              >
                                {`${numeracaoCategorias[subcat._id]} ${
                                  subcat.nome
                                }`}
                              </TableCell>
                            </TableRow>
                            {medicamentosFiltrados
                              .filter((med) => med.categoria._id === subcat._id)
                              .map((medicamento) => (
                                <TableRow
                                  key={medicamento._id}
                                  hover
                                  onClick={() => handleRowClick(medicamento)}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <TableCell>
                                    {medicamento.codNacional}
                                  </TableCell>
                                  <TableCell>{medicamento.codUNSPSC}</TableCell>
                                  <TableCell>
                                    {medicamento.apresentacao}
                                  </TableCell>
                                  <TableCell>
                                    {medicamento.nomeGenerico}
                                  </TableCell>
                                  <TableCell>
                                    {new Date(
                                      medicamento.createdAt
                                    ).toLocaleDateString()}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
};

export default TabelaGeral;
