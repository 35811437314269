/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "@fontsource/roboto";
import { TDocumentDefinitions } from 'pdfmake/interfaces';


pdfMake.vfs = pdfFonts.pdfMake.vfs;
/** REINDERIZAÇÃO DE IMAGEM, PARA CONVERÇÃO **/
function getBase64Image(img: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function abreviarNome(nome: string) {
    if (typeof nome !== 'string' || !nome.trim()) {
        throw new Error('Nome inválido');
    }

    const partes = nome.trim().split(' ');

    const primeiro = partes[0][0];
    const segundo = partes[partes.length - 1][0];
    
    return `${primeiro}.${segundo}`;
}
    /** RECUPERA OS DADOS DO JSON **/


const CertificadoPdf = async (solicitacao: any) => {

const date1 = new Date(solicitacao?.certificado?.createdAt);
const date2 = new Date(solicitacao?.certificado?.dataExpiracao);
const diffInMonths = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
const dataEmissao = date1.toLocaleDateString('pt-BR');
const dataValidade = date2.toLocaleDateString('pt-BR');
const abreviatura = abreviarNome(solicitacao?.importador?.nome);

    /** CONVERÇÃO DE IMAGEM PARA BASE64 **/
    const imageBlob = await fetch('/img/logo/InsigniaAngola.png').then(res => res.blob());
    const imageBase64 = await getBase64Image(imageBlob) as string;
    const imageFundo = await fetch('/img/logo/logoArmed.png').then(res => res.blob());
    const imageFundo64 = await getBase64Image(imageFundo) as string;
    /** CHAMADA DE TIPOS DE FONT**/
    pdfMake.fonts = {
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-BoldItalic.ttf'
        }
    };
    /** INICIALIZAÇÃO DE DOCUMENTOS **/
    const docDefinition: TDocumentDefinitions  = {
        /** COFIGURAÇÃO INICIAL DE DOCUMENTOS **/
        pageSize: 'A4',
        pageMargins: [15, 40, 15, 100],
        /** CONTEUDO PRINCIPAL**/
        content: [
            {
                image: imageBase64,
                width: 50,
                height: 50,
                alignment: 'center',
                margin: [0, 10, 0, 0],
            },
            /** TITULO E SUBTITULO **/
            { text: 'REPÚBLICA DE ANGOLA', style: 'header', },
            { text: 'MINISTÉRIO DA SAÚDE', style: 'header', },
            { text: 'AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE', style: 'header', },
            {
                columns: [
                    {
                        stack: [
                            { canvas: [{ type: 'rect', x: 17, y: 18, w: 540, h: 24, lineWidth: 1, lineColor: '#111' }] },
                            { text: `CERTIFICADO DE IMPORTAÇÃO DE PRECURSORES Nº ${solicitacao?.certificado?.numeroCertificado}`, style: 'subheader', margin: [0, -18, 0, 0] },
                        ],
                    }
                ],
            },
            { text: 'A Agência Reguladora de Medicamentos e Tecnologias de Saúde, entidade reguladora do sector farmacêutico criada pelo Decreto Presidencial nº 136/21 de 1 de Junho, emite o presente certificado conforme previsto nas Leis 3 e 4/99 de 6 de Agosto e nos nº 1 a 6 do artigo 12 do Decreto Presidencial nº 180/10 de 18 de Agosto, sobre a Política Nacional Farmacêutica.', style: 'declaration', },
            { canvas: [{ type: 'rect', x: 17, y: 18, w: 540, h: 74, lineWidth: 1, lineColor: '#111' }] },
            {
                columns: [
                    { text: 'Importador:', style: 'subheader', margin: [0, -70, 0, 0], width: '20%' },
                    {
                        stack: [
                            // second column consists of paragraphs                 
                            { text: '', style: 'body', margin: [0, -70, 0, 10], bold: true },
                            { text: `${solicitacao?.importador.nome}`.toUpperCase(), style: 'body', margin: [0, 0, 20, 0], bold: true },
                            { text: `${solicitacao?.importador.rua}`, style: 'body', margin: [0, 0, 20, 0], },
                            { text: `${solicitacao?.importador.provincia} - Angola`.toUpperCase(), style: 'body', margin: [0, 0, 20, 0], bold: true },
                        ],
                    }
                ],
            },
            { canvas: [{ type: 'rect', x: 17, y: 1, w: 540, h: 104, lineWidth: 1, lineColor: '#111' }] },
            {
                columns: [
                    { text: 'Exportador:', style: 'subheader', margin: [0, -100, 0, 0], width: '20%' },
                    {
                        stack: [                                       
                            { text: '', style: 'body', margin: [0, -100, 0, 10], bold: true },
                            { text: `${solicitacao?.importador.nome}`.toUpperCase(), style: 'body', margin: [0, 0, 20, 0], bold: true },
                            { text: `${solicitacao?.endereco}`, style: 'body', margin: [0, 0, 20, 0], },
                            { text: `${solicitacao?.paisOrigem}`.toUpperCase(), style: 'body', margin: [0, 0, 20, 30], bold: true },
                        ],
                    },
                ],
            },
            { text: `Factura Proforma nº ${solicitacao?.numFaturaPro}`, style: 'body', margin: [30, -30, 0, 7], bold: true,},
            {
                layout: '', // optional
                margin: [17, 5, 7, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [
                        [{
                            columns: [
                                {
                                    stack: [
                                        { text: 'Quantidade, apresentação, designação DCI e designação comercial de substância:', style: 'body', margin: [0, 0, 0, 0], bold: true },
                                        {
                                            stack: solicitacao?.items.map((item: { quantidade: any; formaFarmaceutica: any; pontoEntrada: any; }) => {
                                                return {
                                                    text: `${item.quantidade} ${item.formaFarmaceutica} - ${solicitacao.declaracao.pontoEntrada}`, 
                                                };
                                            }),
                                            style: 'body',
                                            margin: [40, 0, 0, 0],
                                        },
                                    ],
                                    margin: [0, 0, 20, 0],
                                    width: '100%',
                                },
                            ],
                        }]
                    ]
                }
            },
            {
                layout: '', // optional
                margin: [17, 0, 7, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [
                        [{
                            columns: [
                                {
                                    stack: [
                                        { text: 'Teor em base:', style: 'body', margin: [0, 0, 0, 0], bold: true },
                                        {
                                            stack: solicitacao?.items.map((item: { teorBaseG: any; formaFarmaceutica: any; }) => {
                                                return {
                                                    text: `${item?.teorBaseG} ${item.formaFarmaceutica}`,
                                                };
                                            }),
                                            style: 'body',
                                            margin: [40, 0, 0, 0],
                                        },
                                    ],
                                    margin: [0, 0, 20, 0],
                                    width: '100%',
                                },
                            ],
                        }]
                    ]
                }
            },
            { canvas: [{ type: 'rect', x: 17, y: 0, w: 540, h: 25, lineWidth: 1, lineColor: '#111' }] },
            {
                columns: [
                    {
                        stack: [             
                            { text: `Emitido em: ${dataEmissao}`, style: 'body', margin: [0, -20, 0, 0], bold: true },
                        ],
                        margin: [30, 0, 20, 0],
                    },
                ],
            },
            { canvas: [{ type: 'rect', x: 17, y: 0, w: 540, h: 25, lineWidth: 1, lineColor: '#111' }] },
            {
                columns: [
                    {
                        stack: [              
                            { text: `Validade: ${dataValidade} (${diffInMonths} meses)`, style: 'body', margin: [0, -20, 0, 0], bold: true },
                        ],
                        width: '50%',
                    },
                    {
                        width: '50%',
                        text: `${abreviatura}`,
                        style: 'body', margin: [0, -20, 30, 0], bold: true, alignment: 'right'
                    },
                ],
                margin: [30, 0, 0, 30],
            },
            { canvas: [{ type: 'rect', x: 17, y: -30, w: 540, h: 25, lineWidth: 1, lineColor: '#111' }] },
            {
                columns: [
                    {
                        stack: [              
                            { text: 'Via de Expedição: Aérea/Maritima/Terrestre', style: 'body', margin: [0, -25, 0, 0], bold: true },
                        ],
                        margin: [30, 0, 20, 0],
                    },
                ],
            },
            { text: 'O DIRECTOR GERAL'.toUpperCase(), style: 'header', margin: [0, 50, 0, 0] },
        ],
        /**CABEÇARIO**/
        header: function () {
            return [
            ]
        },
        footer: () => {
            return [
                { text: '____________________________________________________________________________________________________', style: '', margin: [9, 0, 0, 0], bold: true, alignment: 'center' },
                {
                    columns: [
                        {
                            stack: [
                                { text: 'Rua Cmdt. Che Guevara nº 6/86º Bº Maculusso, Ingombota', style: '', margin: [0, 10, 0, 0], bold: true },
                                { text: 'Tel.: +244 938 120 372', style: '', margin: [0, 0, 0, 0], bold: true },
                                { text: 'Email: geral@armed.minsa.gov.ao LUANDA-ANGOLA', style: '', margin: [0, 0, 0, 0], bold: true },
                            ],
                            width: '70%',
                            fontSize: 9,
                            alignment: 'left'
                        },
                        {
                            width: '30%',
                            stack: [
                                {
                                    image: imageFundo64,
                                    width: 150,
                                    height: 50,
                                    alignment: 'center',
                                },
                            ]
                        },
                    ],
                    alignment: 'center',
                    fontSize: 8,
                    margin: [50, 0, 50, 0],
                }
            ]
        },
            /** ESTILOS DE DOCUMENTO **/
        styles: {
            header: {
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 0],
            },
            subheader: {
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 0],
            },
            declaration: {
                fontSize: 11,
                bold: true,
                alignment: 'justify',
                margin: [30, 15, 30, 5],
                lineHeight: 1.3,
            },
            body: {
                fontSize: 11,
                alignment: 'justify',
                margin: [50, 10, 50, 5],
                lineHeight: 1.3,
            },
            signature: {
                fontSize: 12,
                bold: false,
                margin: [0, 10, 0, 0],
            },
        },
        defaultStyle: {               
             font: 'Roboto',
        }

    };

    pdfMake.createPdf(docDefinition).open();
   
};
export{
    CertificadoPdf,
}