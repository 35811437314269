import React, { useState, useEffect } from 'react'
import './artigo.css'
import { TextField, MenuItem, Box, Skeleton } from '@mui/material';
import CountrySelect, { CountryType } from '../country/selectCountry';
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/api';
import Date from '../../../../utils/date';


interface Artigo {
    _id: string;
    artigoTitulo: string;
    imagem: string;
    temaArtigo: string;
    tipoArtigo: string;
    editores: string;
    palavrasChaves: string;
    isbn: string;
    escritorio: string;
    anoPublicado: string;
    pais: string;
    visaoGeral: string;
    anexo: string;
    createdAt: string;
}


const ArtigosCientifico: React.FC = () => {
    const navigate = useNavigate();

    const [artigos, setArtigos] = useState<Artigo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [erro, setErro] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<CountryType | null>(null);
    const countryLabel = selectedCountry?.label || ""
    const [filtro, setFiltro] = useState({
        palavrasChaves: '',
        tipoArtigo: '',
        temaArtigo: '',
        escritorio: '',
        ano: '',
    });

    const handleCountryChange = (_event: React.SyntheticEvent<Element, Event>, newValue: CountryType | null,) => {
        setSelectedCountry(newValue);
    };

    const handleChange = (event: any) => {
        setFiltro({ ...filtro, [event.target.name]: event.target.value });
    }


    useEffect(() => {
        setIsLoading(true);
        // setErro('');
        api.get(`/artigos-cientifico?palavrasChaves=${filtro.palavrasChaves}&tipoArtigo=${filtro.tipoArtigo}&temaArtigo=${filtro.temaArtigo}&escritorio=${filtro.escritorio}&anoPublicado=${filtro.ano}&pais=${countryLabel}`)
            .then(res => {
                setIsLoading(false);
                setArtigos(res.data.artigosCientifico);
            })
            .catch(() => {
                setIsLoading(false);
                // setErro("Ocorreu algum erro ao carregar os Artigos");
            });
    }, [filtro.palavrasChaves, filtro.tipoArtigo, filtro.temaArtigo, filtro.escritorio, filtro.ano, countryLabel])


    function SkeletonCard() {
        return (
            <Box>
                <Box sx={{ width: 210, marginRight: 0.5, my: 5 }} className="h-[350px]">
                    <Skeleton animation="wave" variant="rectangular" width={210} className="h-[200px]" />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="60%" />
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        // <Container>
        <div className='artigo-home-artigo'>
            <div className='artigo-home'>
                <h1 style={{ fontSize: '24px' }}>Artigos Ciêntificos</h1>

                <p>Pesquisar artigos</p>

                <div className="artigo-filtro-section">
                    <TextField
                        name='palavrasChaves'
                        id="outlined-basic"
                        label="Pesquisa por Palavras-chaves"
                        variant="outlined"
                        className='artigo-txtTipo artigo-txt'
                        onChange={handleChange}
                    />
                    <TextField
                        disabled
                        id="outlined-select-currency"
                        select
                        label="Tipo"
                        className='artigo-txtTipo artigo-txt'
                        name='tipoArtigo'
                        onChange={handleChange}
                    >
                        {artigos.map((artigo) => (
                            <MenuItem key={artigo._id} value={artigo.tipoArtigo} >
                                {artigo.tipoArtigo}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Tema"
                        className='artigo-txtTipo artigo-txt'
                        name='temaArtigo'
                        onChange={handleChange}
                    >
                        {artigos.map((artigo) => (
                            <MenuItem title={artigo.temaArtigo} key={artigo._id} value={artigo.temaArtigo} >
                                {artigo.temaArtigo.length > 50 ? artigo.temaArtigo.slice(0, 50) + '...' : artigo.temaArtigo}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        disabled
                        id="outlined-select-currency"
                        select
                        label="Escritório de Publicação"
                        className='artigo-txtTipo artigo-txt'
                        name='escritorio'
                        onChange={handleChange}
                    >
                        {artigos.map((artigo) => (
                            <MenuItem key={artigo._id} value={artigo.escritorio} >
                                {artigo.escritorio}
                            </MenuItem>
                        ))}
                    </TextField>

                    <CountrySelect
                        name="country"
                        value={selectedCountry}
                        handleChange={handleCountryChange}
                    />

                    <TextField
                        id="outlined-select-currency"
                        label="Ano"
                        className='artigo-anoTxt artigo-txt'
                        name='ano'
                        onChange={handleChange}
                        style={{ margin: '2px 0' }}
                    />

                    <TextField
                        disabled
                        id="outlined-select-currency"
                        label="Autor / Autores"
                        className='artigo-txtTipo artigo-txt artigo-autor'
                        name='autor'
                        onChange={handleChange}
                        style={{ margin: '2px 0' }}
                    />
                </div>

                {isLoading ?
                    <div className="artigo-pdf-article-cards-container" >
                        {
                            [...Array(4)].map((_, index) => (
                                <div className="artigo-pdf-article-card-card" style={{ cursor: 'progress' }} key={index}>
                                    <SkeletonCard />
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div className="artigo-pdf-article-cards-container">
                        {artigos.map((artigo, index) => (
                            <div className="artigo-pdf-article-card-card" key={index}>
                                <div className="artigo-pdf-article-card-image">
                                    <img src={artigo.imagem} alt='imagem do artigo' />
                                </div>
                                <div className="artigo-pdf-article-card-info">
                                    <p className="artigo-pdf-article-card-date" style={{ fontSize: '16px', padding: '0', marginTop: '20px', textAlign: 'left' }}><Date date={artigo.createdAt}/></p>
                                    <h3 className="artigo-pdf-article-card-title">{artigo.artigoTitulo}</h3>
                                </div>
                                <div className="artigo-pdf-article-card-links">
                                    <a href={artigo.anexo} target="_blank" className="artigo-pdf-article-card-download">Baixar</a>
                                    <a href="" className="artigo-pdf-article-card-read-more">
                                        <div onClick={() => navigate(`/view-artigo-cientifico/${artigo._id}`)}>Leia Mais</div></a>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
        // </Container>
    )
}

export default ArtigosCientifico
