
import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export default function SelecteTipoCertificado() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper
      className='solicitacao-status'
      // component="form"
      sx={{ marginTop: 2, paddingLeft: 1, display: 'flex', alignItems: 'center', background: '#85287e', color: '#fff' }}
    >

      <List
        sx={{ width: '100%' }}
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="Solicitação de Importação"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="Solicitação de Importação"
          />
        </ListItemButton>
      </List>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <MenuItem disabled >
          Selecionar tipo solicitação
        </MenuItem>

        <MenuItem onClick={() => navigate('/solicitar/importacao/produto-controlado')}>
          Solicitar certificado de produtos controlados
        </MenuItem>

        <MenuItem onClick={() => navigate('/solicitar/importacao/produto-nao-controlado')}>
          Solicitar certificado de produtos não controlados
        </MenuItem>
      </Menu>
    </Paper>
  );
}
