import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import api from '../../../../services/api';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../load/loading';
import MenuItem from '@mui/material/MenuItem';
import HeaderSession from '../../../../utils/headerSession';
import { Card, Container, InputBase, TextField, Typography } from '@mui/material';
import RegisterAccess from '../../../../utils/registerAccess';
import { makeStyles } from '@material-ui/core';
import ListaVazia from '../../../../utils/listaVazia';
import Date from '../../../../utils/date';
import StatusEstabelecimento from '../../../../utils/statusEstabelecimento';


const useStyles = makeStyles((theme) => ({
  hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' },
}))

const ListaSolicitacoesImportacao = () => {
  const classes = useStyles();
  const { id } = useParams()
  const [solicitacoes, setSolicitacoes] = useState('');
  const [status, setStatus] = useState('Análise');
  const navigate = useNavigate();

  useEffect(() => {
    setSolicitacoes('');
    api.get(`/solicitacao-importacaos?status=${status}&importador=${id ?? ''}`)
      .then(response => {
        setSolicitacoes(response.data.solicitacaoImportacaos)
      }).catch(err => {
        // console.log(err)
      })
  }, [status, id])


  // const filtraReclamacoes = (valor) => {
  //   setSolicitacoes(filter.filter((espedienteGeral) => (espedienteGeral?.nome?.toLowerCase().includes(valor.toLowerCase()))))
  // }


  return (

    <Container>
      <RegisterAccess page={'lista de solicitações de importação'} />
      {!id &&
        <HeaderSession title={'SOLICITAÇÕES DE IMPORTAÇÃO'} />
      }

      <Card style={{ padding: 20 }}>
        <div style={{ display: 'flex', border: '1px solid #ddd', borderRadius: 5 }}>
          <InputBase
            style={{ width: '100%' }}
            sx={{ ml: 1, flex: 1 }}
            autoFocus
            placeholder="Filtra aquí pelo nome"
            inputProps={{ 'aria-label': 'Filtrar lista' }}
          // onChange={(e) => { filtraReclamacoes(e.target.value) }}
          />
          <TextField
            style={{ width: 200 }}
            type="text"
            label="Estado"
            select
            size="small"
            variant="outlined"
            onChange={(e) => { setStatus(e.target.value === 'Geral' ? '' : e.target.value) }}
            defaultValue={'Análise'}
          >
            <MenuItem value='Geral'>Geral</MenuItem>
            <MenuItem value='Análise'>Análise</MenuItem>
            <MenuItem value='Aprovado'>Aprovado</MenuItem>
            <MenuItem value='Reprovado'>Reprovado</MenuItem>

          </TextField>
        </div>


        {solicitacoes ?
          solicitacoes.length > 0 ?

            <Card variant="outlined" sx={{ width: '100%', overflow: 'hidden' }} >
              <TableContainer id={'listaSolicitacoesImportacao'}>
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" ><Typography noWrap> <strong> Importador </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Solicitação </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Tipo de Certificado </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Fatura Proforma Nº </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Pais de Origem </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Estado </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Data da Solicitação </strong></Typography></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {solicitacoes.map((solicitacao) => (
                      <TableRow key={solicitacao._id} className={classes.hover} onClick={() => { navigate(`/admin/dmts/gest/solicitacao-de-importacao/${solicitacao._id}`) }}>

                        <TableCell ><Typography noWrap> {solicitacao.importador?.nome}</Typography></TableCell>
                        <TableCell ><Typography noWrap> {solicitacao.numero}</Typography></TableCell>
                        <TableCell  ><Typography > {solicitacao.tipoCertificado}</Typography></TableCell>
                        <TableCell  ><Typography noWrap> {solicitacao.numFaturaPro}</Typography></TableCell>
                        <TableCell  ><Typography noWrap> {solicitacao.paisOrigem}</Typography></TableCell>
                        <TableCell align="center" ><Typography noWrap> <StatusEstabelecimento status={solicitacao?.status} /> </Typography></TableCell>
                        <TableCell align="center" ><Typography noWrap> <Date date={solicitacao?.createdAt} /> </Typography></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <FerramentaNavegacaoExportacao
                                FerramentaNavegacaoExportacao={true}
                                // exportToPDF={exportToPDF}
                                // activeImpressao={activeImpressao}
                                print={print}
                                configLista={configLista}
                                lista={solicitacoes}
                                page={page}
                                setPage={setPage}
                            /> */}
            </Card>
            :
            <ListaVazia />
          :
          <Loading text={'A carregar solicitações'} />
        }
      </Card>
    </Container>

  )
}

export default ListaSolicitacoesImportacao 
