import { Card, IconButton } from "@mui/material";
import HeaderGestorConfigs from "../../../../../utils/headerGestorConfigs";
// import VisibilityIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Date from "../../../../../utils/date";
import { CertificadoPdf } from "./certificado/certificadopdf";
import { DesalfandegamentoPDF } from "./desalfandegamento/desalfandegamentoPDF";
import { DesalfandegamentoBlob } from "./desalfandegamento/desalfandegamentoBlob";


export default function DocumentosEmitidos({ solicitacaoImportacao }) {

    return (
        <Card style={{ padding: 15, borderRadius: 5 }}>

            <HeaderGestorConfigs title={'DOCUMENTOS EMITIDOS'} />

            {solicitacaoImportacao?.certificado &&
                <p>
                    {/* <IconButton color="secondary"
                    // onClick={() => CertificadoPdf()}
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton> */}
                    <IconButton color="secondary"
                        onClick={() => { CertificadoPdf(solicitacaoImportacao) }}
                    >
                        <DownloadOutlinedIcon />
                    </IconButton>

                    CERTIFICADO DE IMPORTAÇÃO_{solicitacaoImportacao?.certificado?.numeroCertificado} _ <Date date={solicitacaoImportacao?.certificado.dataEmissao} />
                </p>
            }

            {(solicitacaoImportacao?.declaracao && solicitacaoImportacao?.declaracao?.dataAprovacao) &&
                <p>
                    {/* <IconButton color="secondary"
                    // onClick={() => CertificadoPdf()}
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton> */}
                    <IconButton color="secondary"
                    onClick={() => {
                        // DesalfandegamentoBlob(solicitacaoImportacao);
                        DesalfandegamentoPDF(solicitacaoImportacao);
                    }}
                    >
                        <DownloadOutlinedIcon />
                    </IconButton>

                    DECLARAÇÃO DE DESALFANDEGAMENTO_{solicitacaoImportacao?.declaracao?.numeroDeclaracao} _ <Date date={solicitacaoImportacao?.declaracao.dataDespacho} />
                </p>
            }


            {/* <iframe
        id="pdfIframe"
        title={`PDF Viewer`}
        // src={viewRelatorio}
        width="100%"
        height="500px"
        style={{ border: 'none' }}
    /> */}
        </Card>
    )
}
