
import { useEffect, useState } from 'react'
import { Typography, Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, IconButton, TextField, MenuItem, Button, Card, DialogActions, Tooltip, Collapse, ListItemIcon, ListItemButton } from '@mui/material'
import api from '../../../../../services/api';
import Mes from '../../../../../utils/mes';
import Loading from '../../../../../load/loading';
import { useDispatch, useSelector } from 'react-redux';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MessageAlert from '../../../../../messages/messageAlert';
import LoadingBackdrop from '../../../../../load/loadingBackdrop';
import HeaderGestorConfigs from '../../../../../utils/headerGestorConfigs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import FilePresentIcon from '@mui/icons-material/FilePresent';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { useNavigate } from 'react-router-dom';
import ReprovarInspeccaoRealizada from './reprovarInspeccaoRealizada';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import compressImage from '../../../../../services/compressImage';
import LoadingShort from '../../../../../load/loadingShort';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RemoverInspector from './removerInspector';
import DeleteImageInspeccao from './deleteImageInspeccao';


export default function CardInspeccao({ generatePDF, thisInspeccao, viewRelatorio, setRefresh }) {
    // status é uma variavel que indica se inspeccao esta aberta ou fechada
    const [openImagensCapturadas, setOpenImagensCapturadas] = useState(false);
    const user = useSelector(state => state.account.user);
    const [inspeccao, setInspeccao] = useState(thisInspeccao)
    const [inspector, setInspector] = useState('')
    const [admins, setAdmins] = useState('')
    const [addInspector, setAddInspector] = useState(false)
    const [addOutrosInspector, setAddOutrosInspector] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false)
    const [loadAddImage, setLoadAddImage] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    console.log(inspeccao._id)

    useEffect(() => {
        api.get('/admins')
            .then(response => {
                setAdmins(response.data.Admins)
            }).catch(err => '')
    }, [])

    function HandleChange(e) {

        e.target.files ?
            setInspector({ ...inspector, [e.target.name]: e.target.files[0] })
            :
            setInspector({ ...inspector, [e.target.name]: e.target.value })
    }

    const HandleAddInspector = async () => {
        setLoad(true)
        setMessageSuccess('')
        setMessageError('')

        await api.patch('/inspeccao/add-inspector',
            {
                ...inspector,
                inspeccaoId: inspeccao._id,
                userId: user._id
            })
            .then(res => {
                setLoad(false)
                setMessageSuccess(res.data.msg)
                setAddInspector(false)
                setInspeccao(res.data.inspeccao)

                // enviar e-mail de notificação de andamento do processo
                // senEmail()

            }).catch(err => {
                setLoad(false)
                setMessageError(err.response.data.message)
            })
    }

    const HandleAddOutrosInspector = async () => {
        setLoad(true)
        setMessageSuccess('')
        setMessageError('')

        await api.patch('/inspeccao/add-outros-inspectores',
            {
                ...inspector,
                inspeccaoId: inspeccao._id,
                userId: user._id
            })
            .then(res => {
                // console.log(res)
                setLoad(false)
                setMessageSuccess(res.data.msg)
                setAddOutrosInspector(false)
                setInspeccao(res.data.inspeccao)

                // enviar e-mail de notificação de andamento do processo
                // senEmail()

            }).catch(err => {
                setLoad(false)
                setMessageError(err.response.data.message)
            })
    }


    const startInspeccao = () => {
        dispatch({
            type: 'inspeccao',
            payload: {
                inspeccao: inspeccao
            }
        })
        navigate(`/admin/modo-inspeccao/${inspeccao.estabelecimento._id} `)
    }

    async function postImage(e) {

        setLoadAddImage(true)
        let imageComprimida = await compressImage(e.target.files[0])

        const formData = new FormData();
        formData.append('fotos', imageComprimida);
        formData.append('inspeccaoId', inspeccao?._id);
        formData.append('userId', user?._id);

        await api.patch(`/inspeccao/add-fotos`, formData)
            .then(response => {
                setLoadAddImage(false)
                setRefresh(response)
                // console.log(response)

                dispatch({
                    type: 'alert',
                    payload: { alert: 'Imagem foi adicionada.!' },
                });

            }).catch(err => {
                setLoadAddImage(false)
                // console.log(err)
            })
    }

    const CaptureImage = () => {
        return (
            <>
                <IconButton title='Adicionar mais imagens' size='large' style={{ marginLeft: '95%' }} >
                    {loadAddImage ? <LoadingShort />
                        :
                        <label htmlFor="capitureImage" >
                            <CameraAltIcon />
                        </label>
                    }
                </IconButton>
                <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="capitureImage" style={{ display: 'none' }} onChange={postImage} />
            </>
        )
    }

    return (
        inspeccao
            ?
            <>
                {messageSuccess && <MessageAlert message={messageSuccess} type={'success'} />}
                <LoadingBackdrop open={load} text={load} />
                {messageError && <MessageAlert message={messageError} type={'error'} />}

                {/* <button onClick={()=>console.log(inspeccao._id)}>teste</button> */}
          
                <Card style={{ padding: 15, borderRadius: 5 }}>
                    <p>Estado: {inspeccao.status}</p>

                    {inspeccao.status === "Não Realizada" &&
                        <>
                            <Typography>
                                <CalendarMonthIcon />
                                Para mês de <Mes mes={inspeccao.dataAgendada?.mes} />
                            </Typography>
                            <HeaderGestorConfigs title={` Inspecção agendada para o dia ${inspeccao.dataAgendada?.dia} de ${inspeccao.dataAgendada?.mes} as ${inspeccao.dataAgendada?.hora < 10 ? 0 : ''}${inspeccao.dataAgendada?.hora}:${inspeccao.dataAgendada?.minuto < 10 ? 0 : ''}${inspeccao.dataAgendada?.minuto}`} />
                        </>
                    }

                    <div style={{ padding: 10, position: 'relative' }}>

                        {/* {(inspeccao.status === "Realizada" || inspeccao.status === "Reprovada") && */}
                        {(inspeccao.status === "Realizada" || inspeccao.status === "Reprovada") &&
                            <>
                                {/* REPROVAR INSPECCAO REALIZADA */}
                                {((user?.funcao === 'Chefe de Departamento' || user?.funcao === 'CNP') && !inspeccao?.estabelecimento?.approved) &&
                                    <ReprovarInspeccaoRealizada inspeccao={inspeccao} />
                                }
                                
                                <Tooltip title="Abrir Relatório">
                                    <Button onClick={() => generatePDF('print')}>
                                        <ZoomOutMapIcon />
                                    </Button>
                                </Tooltip>

                                {viewRelatorio &&
                                    <iframe
                                        id="pdfIframe"
                                        title={`PDF Viewer`}
                                        src={viewRelatorio}
                                        width="100%"
                                        height="800px"
                                        style={{ border: 'none' }}
                                    />
                                }

                                {/* LISTA DE IMAGENS CAPTURADAS */}
                                <List
                                    sx={{ width: '100%', minWidth: '100%', bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    <ListItemButton onClick={() => setOpenImagensCapturadas(!openImagensCapturadas)}>
                                        <ListItemIcon>
                                            {/* <CameraAltIcon /> */}
                                            {inspeccao.fotos.map((foto, index) =>
                                                index < 10 &&
                                                <Avatar key={index} variant='square' id={index} src={foto} style={{ margin: 5 }} />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText primary="IMAGENS CAPTURADAS" />
                                        {openImagensCapturadas ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>

                                    {/* ADICIONAR MAIS IMAGENS NA INSPECCAO */}
                                    {!inspeccao?.estabelecimento?.approved && <CaptureImage />}

                                    <Collapse in={openImagensCapturadas} timeout="auto" unmountOnExit>

                                        {inspeccao.fotos.map((foto, index) =>
                                            <div key={index} style={{ position: 'relative' }}>
                                                {!inspeccao?.estabelecimento?.approved &&
                                                    <DeleteImageInspeccao
                                                        index={index}
                                                        inspeccaoId={inspeccao?._id}
                                                        setMessageSuccess={setMessageSuccess}
                                                        setMessageError={setMessageError}
                                                        setRefresh={setRefresh}
                                                    />
                                                }
                                                <img id={index} src={foto} style={{ marginBottom: 10 }} />
                                            </div>
                                        )}

                                        <DialogActions>
                                            <IconButton onClick={() => setOpenImagensCapturadas(!openImagensCapturadas)} ><ExpandLess /> </IconButton>
                                        </DialogActions>
                                    </Collapse>
                                </List>
                            </>
                        }

                        <Typography marginTop={5}>
                            Inspectores da ARMED
                        </Typography>

                        <List sx={{ width: '100%' }}>
                            {inspeccao.inspectores.map((inspector, index) => (

                                <ListItem key={inspector._id} >
                                    <ListItemAvatar>
                                        <Avatar src={inspector?.dadosPessoais?.fotoURL} style={{ marginRight: 5 }} />
                                    </ListItemAvatar>
                                    <ListItemText primary={inspector?.dadosPessoais?.nome} secondary={index === 0 && 'Inspector Principal'} />

                                    {((user?.funcao === 'Chefe de Departamento' || user?.funcao === 'CNP') && !inspeccao?.estabelecimento?.approved && inspeccao.inspectores.length > 1) &&
                                        <RemoverInspector
                                            setMessageSuccess={setMessageSuccess}
                                            setMessageError={setMessageSuccess}
                                            inspectorId={inspector._id}
                                            inspeccaoId={inspeccao._id}
                                            setRefresh={setRefresh}
                                        />
                                    }
                                </ListItem>
                            ))}

                            {inspeccao.status === "Não Realizada" &&
                                <ListItem >
                                    <ListItemAvatar>
                                        <IconButton onClick={() => setAddInspector(!addInspector)}>
                                            <PersonAddAlt1Icon />
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText primary='' secondary='Adicionar mais inspector' />
                                </ListItem>
                            }
                        </List>


                        {addInspector &&
                            <>
                                <TextField
                                    margin='normal'
                                    required
                                    type="text"
                                    label="Selecionar Inspector"
                                    select
                                    fullWidth
                                    size="small"
                                    name="inspectorId"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={''}
                                >
                                    {admins &&
                                        admins.map((admin) => (
                                            admin.dadosPessoais.perfil === 'Inspector' &&
                                            <MenuItem key={admin._id} value={admin._id} >
                                                <Avatar src={admin.dadosPessoais?.fotoURL} style={{ marginRight: 5 }} />
                                                {admin.dadosPessoais?.nome} - <b>{admin.dadosPessoais?.provincia}, {admin.dadosPessoais?.municipio}</b>
                                            </MenuItem>
                                        ))}
                                </TextField>
                                <Button onClick={HandleAddInspector} color="success" variant="contained">Adicionar</Button>
                            </>
                        }
                    </div>

                    <Divider />

                    <div style={{ padding: 10 }}>
                        <Typography>
                            Outros Inspectores
                        </Typography>
                        <List sx={{ width: '100%' }}>
                            {inspeccao.outrosInspectores.map((inspector) => (

                                <ListItem key={inspector._id} >
                                    {/* <ListItemAvatar>
                                        <Avatar src={inspector?.fotoURL} style={{ marginRight: 5 }} />
                                    </ListItemAvatar> */}
                                    <ListItemText primary={inspector?.nome} secondary={inspector?.instituicao + ' - ' + inspector?.funcao} />
                                </ListItem>
                            ))}

                            {inspeccao.status === "Não Realizada" &&
                                <ListItem >
                                    <ListItemAvatar>
                                        <IconButton onClick={() => setAddOutrosInspector(!addOutrosInspector)}>
                                            <PersonAddAlt1Icon />
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText primary='' secondary='Adicionar mais inspector' />
                                </ListItem>
                            }
                        </List>
                        {addOutrosInspector &&
                            <>
                                <TextField
                                    required
                                    type="text"
                                    fullWidth
                                    label="Nome"
                                    size="small"
                                    name="nome"
                                    variant="outlined"
                                    onChange={HandleChange}
                                />

                                <TextField
                                    required
                                    margin='normal'
                                    type="text"
                                    label="Função"
                                    fullWidth
                                    size="small"
                                    name="funcao"
                                    variant="outlined"
                                    onChange={HandleChange}
                                />

                                <TextField
                                    required
                                    type="text"
                                    label="Instituição"
                                    fullWidth
                                    size="small"
                                    name="instituicao"
                                    variant="outlined"
                                    onChange={HandleChange}
                                />

                                <Button onClick={HandleAddOutrosInspector} color="success" variant="contained" style={{ marginTop: 10 }}>Adicionar</Button>
                            </>
                        }
                    </div>

                    {(inspeccao.status === "Não Realizada" || inspeccao.status === "Iniciada") &&
                        <>
                            <DialogActions>
                                <Typography> {inspeccao?.estabelecimento.nome}  </Typography>
                            </DialogActions>
                            <DialogActions>
                                <Button onClick={startInspeccao} >Iniciar modo Inspecção</Button>
                            </DialogActions>
                        </>
                    }
                </Card>
            </>
            :
            <Loading />
    );
}
