import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "./protectedRouter";
import TemplateAdmin from "../admin/template/templateAdmin";
import ListReclamacoes from "../admin/gestReclamacoes/listReclamacoes";
import ViewReclamacao from "../admin/gestReclamacoes/viewReclamacao";

// INICIO GEST-LICENCIADOS 
import GestLicenciados from "../admin/gestLicenciados";
import EstabelecimentosLicenciados from "../admin/gestLicenciados/estabelecimentosLicenciados";
import Combine from "../admin/gestLicenciados/combine";

// INICIO GEST-EMPRESAS 
import CadastroEmpresa from "../admin/gestEmpresas/cadastro";
import GestEmpresas from "../admin/gestEmpresas";
import Empresas from "../admin/gestEmpresas/empresas";
import GetEmpresa from "../admin/gestEmpresas/getEmpresa";
// INICIO GEST-REPRESENTANTES
import GestRepresentantes from "../admin/gestRepresentantes";
import CadastroRepresentante from "../admin/gestRepresentantes/cadastroRepresentante";
import Representantes from "../admin/gestRepresentantes/representantes";
import GetRepresentante from "../admin/gestRepresentantes/getRepresentante";

// INICIO GEST-DIRECTOR-TECNICO 
import GestDirectorTecnico from "../admin/gestDirectorTecnico";
import DirectoresTecnicos from "../admin/gestDirectorTecnico/directoresTecnicos";
import GetDirectorTecnico from "../admin/gestDirectorTecnico/getDirectorTecnico";
import CadastroDirectorTecnico from "../admin/gestDirectorTecnico/cadastroDirectorTecnico";

import ExportFixaEstabelecimento from "../admin/gestLicenciados/exports/exportFixaEstabelecimento";
import CategoriasDT from "../admin/gestDirectorTecnico/categorias/categoriasDT";
import ExportQRcodeEstabelecimento from "../admin/gestLicenciados/exports/exportQRcodeEstabelecimento";
import GetInspeccoes from "../admin/departamentos/dlif/inspeccao/getInspeccoes";
import AutorizacaoExercicio from "../admin/gestLicenciados/autorizacoesExercicioEmitidas";
import HistoricoActividadesByModel from "../admin/departamentos/dlif/licenciamento/historicoActividadesByModel.jsx.jsx";
import Observacoes from "../admin/gestLicenciados/observacoes";
import ResponsavelDeRegisto from "../admin/gestLicenciados/responsavelDeRegisto";
import GestDocs from "../admin/gestDocs";
import GestArtigosCientificos from "../pages/home/artigosCientifico";
import Chat from "../utils/chat";
import GestSite from "../admin/gestInformacao/site";
import Login from "../pages/login/LoginAdmin";
import LoginRoute from "./loginRoute";
// import LoginRoute from "./loginRoute";


const dlif_dmts = ['DLIF', 'DMTS', 'CNP', 'DG']

const CommonRoutes = ({ user }) => [

  <Route key="00000" element={<LoginRoute isAuthenticated={user} > <Login /> </LoginRoute>} path="/login" />,

  // {/* RECLAMACOES */}
  <Route key="00001"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ListReclamacoes />} /> </ProtectedRoute>} path="/admin/reclamacoes" />,
  <Route key="0000"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ViewReclamacao />} /> </ProtectedRoute>} path="/admin/reclamacao/:id" />,

  // {/* Gest-Licenciados */}
  <Route key="00002"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<EstabelecimentosLicenciados />} /> </ProtectedRoute>} path="/admin/gestor-estabelecimentos-licenciados" />,
  <Route key="00003"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<Combine />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/:id" />,
  <Route key="00004"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<Observacoes />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/observacoes/:id" />,
  <Route key="00005"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<GetInspeccoes />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/inspecao/:id" />,
  <Route key="00006"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<ResponsavelDeRegisto />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/responsavel-pelo-registo/:id" />,
  <Route key="00007"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<HistoricoActividadesByModel />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/historicos/:id" />,
  <Route key="00008"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<AutorizacaoExercicio />} />} /> </ProtectedRoute>} path="/admin/autorizacao-de-exercicio/:id" />,
  <Route key="00009"  element={<ExportFixaEstabelecimento />} path="/exportar-ficha-estabelecimento/:id" />,
  <Route key="000010"  element={<ExportQRcodeEstabelecimento />} path="/exportar-qrcode-estabelecimento/:id" />,

  // {/* Gest-EMPRESAS */}
  <Route key="000011"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestEmpresas element={<Empresas />} />} /> </ProtectedRoute>} path="/admin/gestor-empresas" />,
  <Route key="000012"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestEmpresas element={<GetEmpresa />} />} /> </ProtectedRoute>} path="/admin/get-empresa/:id" />,
  <Route key="000013"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestEmpresas element={<CadastroEmpresa />} />} /> </ProtectedRoute>} path="/admin/cadastro-empresa" />,

  // {/* Gest-REPRESENTANTES */}
  <Route key="000014"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestRepresentantes element={<Representantes />} />} /> </ProtectedRoute>} path="/admin/gestor-representantes" />,
  <Route key="000015"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestRepresentantes element={<GetRepresentante />} />} /> </ProtectedRoute>} path="/admin/get-representante/:id" />,
  <Route key="000016"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestRepresentantes element={<CadastroRepresentante cadastro={true} />} />} /> </ProtectedRoute>} path="/admin/cadastro-representante" />,

  // {/* GEST-DIRECTOR-TECNICO */}
  <Route key="000017"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<DirectoresTecnicos />} />} /> </ProtectedRoute>} path="/admin/gestor-director-tecnicos" />,
  <Route key="000018"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<GetDirectorTecnico />} />} /> </ProtectedRoute>} path="/admin/get-director-tecnico/:id" />,
  <Route key="000019"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<CadastroDirectorTecnico cadastro={true} />} />} /> </ProtectedRoute>} path="/admin/cadastro-director-tecnico" />,
  <Route key="000020"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<CategoriasDT />} />} /> </ProtectedRoute>} path="/admin/categorias-director-tecnico" />,

  // {/* Gest-SITE */}
  <Route key="000021"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestSite />} /> </ProtectedRoute>} path="/admin/gestor-site" />,
  // {/* Gest-DOCS */}
  <Route key="000022"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDocs />} /> </ProtectedRoute>} path="/admin/gestor-docs" />,
  // {/* Gest-Artigos-Cientificos */}
  <Route key="000023"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestArtigosCientificos />} /> </ProtectedRoute>} path="/admin/gestor-artigos-cientificos" />,
  // {/* Chat -  CIP */}
  <Route key="000024"  element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<Chat />} /> </ProtectedRoute>} path="/admin/cip" />,

]


export default CommonRoutes;
