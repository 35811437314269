
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import MessageSuccess from "../../../../../messages/messageSuccess";
import MessageError from "../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
import RegisterAccess from "../../../../../utils/registerAccess";
import HeaderSession from "../../../../../utils/headerSession";
import ButtonLeft from "../../../../../utils/buttonLeft";
import { Card, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InputDocUpload from "../../../../../utils/inputFileUpload/inputDocUpload";
import * as yup from 'yup'; // VALIDACAO
import FichaSolicitacaoImportacao from "../fichaSolicitacaoImportacao";
import Loading from "../../../../../load/loading";
import { useDispatch } from "react-redux";


export default function FormSoliciteDesalfandegamento() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [solicitacao, setSolicitacao] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false)
    const { id } = useParams()
    const [solicitacaoImportacao, setSolicitacaoImportacao] = useState('')

    useEffect(() => {
        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response)
                setSolicitacaoImportacao(response.data.solicitacaoImportacao)
            }).catch(err => 'console.log(err)')
    }, [id])


    async function HandleChangeFile(file, name) {
        setSolicitacao((prevCadastro) => ({ ...prevCadastro, [name]: file }))
    }

    function handleChange(e) {
        e.target.files ?
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.files[0] })
            :
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.value })
    }

    async function createSolicitacaoDesalfandegamento() {
        const formData = new FormData();

        formData.append("numeroPorte", solicitacao?.numeroPorte)
        formData.append("numFatura", solicitacao?.numFatura)
        formData.append("destino", solicitacao?.destino)
        formData.append("pontoEntrada", solicitacao?.pontoEntrada)
        formData.append("importadorId", solicitacaoImportacao.importador._id)
        formData.append("solicitacaoImportacaoId", id)

        // Docs
        formData.append("solicitacao", solicitacao?.solicitacao ?? '') // 
        formData.append("cartaPorteBl", solicitacao?.cartaPorteBl ?? '') // 
        formData.append("certificadoCQL", solicitacao?.certificadoCQL ?? '') // 
        formData.append("certificadoOP", solicitacao?.certificadoOP ?? '') // 
        // formData.append("folhetoILP", solicitacao?.folhetoILP ?? '') // 
        formData.append("faturaFinal", solicitacao?.faturaFinal ?? '') // 
        formData.append("faturaEmolumento", solicitacao?.faturaEmolumento ?? '') // 

        setOpen(true)
        setErrorMessage('')
        setMessage('')

        await api.post('/declaracao-desalfandegamento/register', formData)
            .then(res => {
                setMessage(res.data.msg)
                dispatch({
                    type: 'alert',
                    payload: { alert: 'Solicitação foi enviada com sucesso.!' },
                });
                navigate(`/utente/solicitacao/importacao/${id}`)
                // console.log(res)
            }).catch(error => {
                setErrorMessage(error.response.data.message)
                // console.log(error)
            }).finally(() => setOpen(false))
    }

    async function validate() {
        setErrorMessage('');

        let schema = yup.object().shape({
            faturaEmolumento: yup.string("Necessário fazer o upload da Factura de pagamento de emolumento Armed").required("Necessário fazer o upload da Factura de pagamento de emolumento Armed"),
            faturaFinal: yup.string("Necessário fazer o upload da Factura Final dos produtos importados, na língua portuguesa").required("Necessário fazer o upload da Factura Final dos produtos importados, na língua portuguesa"),
            // folhetoILP: yup.string("Necessário fazer o upload dos folhetos informativos em língua portuguesa").required("Necessário fazer o upload dos folhetos informativos em língua portuguesa"),
            certificadoOP: yup.string("Necessário fazer o upload do Certificado de Origem dos produtos").required("Necessário fazer o upload do Certificado de Origem dos produtos"),
            certificadoCQL: yup.string("Necessário fazer o upload do Certificado de controlo de qualidade por lotes").required("Necessário fazer o upload do Certificado de controlo de qualidade por lotes"),
            cartaPorteBl: yup.string("Necessário fazer o upload da Carta de porte ou BL").required("Necessário fazer o upload da Carta de porte ou BL"),
            solicitacao: yup.string("Necessário fazer o upload da carta dirigida à Armed").required("Necessário fazer o upload da carta dirigida à Armed"),

            pontoEntrada: yup.string("Necessário selecionar o ponto de entrada").required("Necessário selecionar o ponto de entrada"),
            destino: yup.string("Necessário selecionar o destino dos produtos").required("Necessário selecionar o destino dos produtos"),
            numFatura: yup.string("Necessário inserir o número de factura emitida pela Armed").required("Necessário inserir o número de factura emitida pela Armed"),
            numeroPorte: yup.string("Necessário inserir número de porte").required("Necessário inserir o número de porte"),
        })

        try {
            await schema.validate(solicitacao);
            createSolicitacaoDesalfandegamento();
        } catch (error) {
            setErrorMessage(error.errors);
        }
        return false
    }


    return (
        <div>
            <RegisterAccess page={'solicitação de importação'} />
            <LoadingBackdrop open={open} text={'Enviando solicitação.!'} />

            <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
                <HeaderSession title={`SOLICITAÇÃO DE DESALFANDEGAMENTO`} />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}

                {solicitacaoImportacao ?
                    <>
                        <FichaSolicitacaoImportacao solicitacaoImportacao={solicitacaoImportacao} />

                        <Typography variant="h5" m={2}>
                            Preenche o formulário:
                        </Typography>

                        <Card style={{ padding: 10 }}>

                            <Grid container>
                                <Grid xs={12} md item m>
                                    <TextField
                                        type="text"
                                        label="Nº de Porte"
                                        fullWidth
                                        name="numeroPorte"
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <TextField
                                        type="text"
                                        label="Nº da Factura (Emolumento Armed)"
                                        fullWidth
                                        name="numFatura"
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container>

                                <Grid xs={12} md item m>

                                    <FormControl fullWidth>
                                        <InputLabel id="pontoEntrada-select-label">Ponto de entrada</InputLabel>
                                        <Select
                                            labelId="pontoEntrada-select-label"
                                            id="pontoEntrada-select"
                                            label="Ponto de entrada"
                                            name='pontoEntrada'
                                            onChange={handleChange}
                                        // defaultValue={addItem?.pontoEntrada ?? ''}
                                        >
                                            <MenuItem value="" disabled>...</MenuItem>
                                            <MenuItem value="Porto">Porto</MenuItem>
                                            <MenuItem value="Aeroporto">Aeroporto</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid xs={12} md item m>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Destino</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Destino"
                                            name='destino'
                                            onChange={handleChange}
                                        // defaultValue={addItem?.moedas ?? ''}
                                        >
                                            <MenuItem value="" disabled>...</MenuItem>
                                            <MenuItem value="Comercialização">Comercialização</MenuItem>
                                            <MenuItem value="Doação">Doação</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* ANEXAR DOCUMENTOS */}
                            <Typography align="center" m={2} color={'secondary'}>ANEXAR DOCUMENTOS </Typography>

                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'solicitacao'}
                                        inputTitle={'Carta dirigida a ARMED'}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'cartaPorteBl'}
                                        inputTitle={'Carta de porte ou BL'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'certificadoCQL'}
                                        inputTitle={'Certificado de controlo de qualidade por lotes'}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'certificadoOP'}
                                        inputTitle={'Certificado de Origem dos produtos'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'faturaFinal'}
                                        inputTitle={'Factura Final dos produtos importados, na língua portuguesa'}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <InputDocUpload
                                        onFileSelect={HandleChangeFile}
                                        inputName={'faturaEmolumento'}
                                        inputTitle={'Factura de pagamento de emolumento na Armed'}
                                    />
                                </Grid>
                            </Grid>

                            <ButtonLeft title={'Submeter'} funcao={validate} disabled={!solicitacao} />
                        </Card>
                    </>

                    :
                    <Loading />
                }

            </Container>
            <br />
        </div>
    );
}
