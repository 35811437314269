
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles((theme) => ({
    root: {
        height: 50,
        width: '100%',
        background: '#85287e',
        borderRadius: 5,
        transition: '0.3s',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        '&:hover': {
            boxShadow: '2px 2px 2px #67348d'
        }
    },
}))

type cardServicesProps = {
    // imageView: string,
    // imageDowload: string,
    nomeOpcao: string;
    link: string;
    view?: boolean;
}
export default function ListaOpcoes({ nomeOpcao, link, view }: cardServicesProps) {
    const classes = useStyles()

    return (
        <div style={{ display: 'flex', gap: '1rem', marginTop: 15, }}>
            
            {view &&
                <>
                    <Link style={{ textDecoration: 'none' }} to={link}>
                        <img src='/img/icones/view.svg' alt="" />
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={link}>
                        <img src='/img/icones/downloadicon.svg' alt="" />
                    </Link>
                </>
            }

            <CardActionArea className={classes.root} >
                <Link style={{ textDecoration: 'none' }} to={link}>
                    <CardContent style={{ padding: 5 }}>
                        <Typography variant="subtitle1" style={{ lineHeight: 1, fontSize: 16, color: '#fff', paddingLeft: 20 }}>
                            {nomeOpcao}
                        </Typography>
                    </CardContent>
                </Link>
            </CardActionArea>
        </div>
    )
}