import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from '../../actions/accountActions';
import LoadingBackdrop from '../../load/loadingBackdrop';
import { ListItemText } from '@mui/material';
import Check from '@mui/icons-material/Check';

export default function UserAdmin() {
    const url = useLocation();
    const user = useSelector(state => state.account.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [load, setLoad] = React.useState(false)
    const trocaDepartamento = (url) => {
        setLoad('A mudar Departamento')

        setTimeout(() => {

            navigate(url)
            // window.location.reload()

            dispatch({
                type: 'alert',
                payload: { alert: 'Mudou de Departamento' },
            });

            setLoad(false)
        }, 3000);
    };

    const handleSignOut = () => {
        setLoad('A terminar sessão')

        setTimeout(() => {
            dispatch(signOut());
            handleClose();
            navigate('/login')
            window.location.reload()

            dispatch({
                type: 'alert',
                payload: { alert: 'Sessão terminada' },
            });
        }, 4000);
    };

    const Checked = ({ departamento }) => {
        return (
            url.pathname.includes(departamento) &&
            <ListItemIcon>
                <Check color='secondary' />
            </ListItemIcon>
        )
    }

    return (
        <React.Fragment>
            <LoadingBackdrop text={load} open={!!load} />

            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                <Tooltip title="Definiçōes de conta">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        // sx={{ ml: 1 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar
                            // sx={{ width: 32, height: 32 }}
                            alt={user?.dadosPessoais?.nome}
                            src={user?.dadosPessoais?.fotoURL}
                            style={{ cursor: 'pointer' }}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate('/admin/perfil-admin')}>
                    <Avatar
                        // sx={{ width: 32, height: 32 }}
                        alt={user?.dadosPessoais?.nome}
                        src={user?.dadosPessoais?.fotoURL}
                        style={{ cursor: 'pointer' }}
                    />
                    Perfil
                </MenuItem>


                <Divider />
                {/* {(user?.funcao === 'CNP' || user?.funcao === 'Chefe de Departamento' || user?.funcao === 'Director Geral') && */}
                {user?.funcao === 'CNP' &&
                    <div>
                        <MenuItem disabled>
                            Departamentos:
                        </MenuItem>

                        <MenuItem onClick={() => trocaDepartamento('/admin/dlif')}>
                            <Checked departamento={'dlif'} />
                            <ListItemText >DLIF</ListItemText>
                        </MenuItem>

                        <MenuItem onClick={() => trocaDepartamento('/admin/dmts')}>
                            <Checked departamento={'dmts'} />
                            <ListItemText >DMTS</ListItemText>
                        </MenuItem>

                        <Divider />
                    </div>
                }

                {/* <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}

                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Terminar sessão
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
