import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { register } from '../services/webAccessRegister';
import axios from 'axios';

export default function RegisterAccess({ page }) {
    const user = useSelector(state => state.account.user);
    // const [location, setLocation] = useState({ latitude: null, longitude: null });
    // const [error, setError] = useState(null);
    // const [locationInfo, setLocationInfo] = useState({ country: '', city: '' });
    // const [loading, setLoading] = useState(false);
    // console.log(page)
    // const isDevelopment = process.env.NODE_ENV === 'development';
    // console.log(user)

    // return null

    useEffect(() => {
        // setLoading(true);
        // if (navigator.geolocation & !isDevelopment) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // setLocation({ latitude, longitude });
                    fetchLocationInfo(latitude, longitude);
                },
                (error) => {
                    // setError(error.message);
                    // setLoading(false);
                    // console.log(error)
                    register({
                        userId: user?._id ?? '',
                        page: page,
                        country: 'erro ao pegar pais',
                        state: 'erro ao pegar provincia',
                        city: 'erro ao pegar cidade',
                        road: 'erro ao pegar bairro',
                        formatted: '',
                        lat: '',
                        lon: '',
                        operator: '',
                        device: navigator.userAgent,
                        ip: ''
                    })
                }
            );
        } else {
            // console.log(navigator.geolocation.getCurrentPosition.coords)
            // console.log('Ambiente de desenvolvimento')
            // console.log(location)
            // setError('Geolocalização não é suportada pelo seu navegador.');
            // setLoading(false);
        }
    }, [page, user?._id]) 


    const fetchLocationInfo = async (latitude, longitude) => {
        try {
            const response = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=577c2b1439e64ef98c63d5cbecdb6fe0`);
            // console.log(response)
            const { country, county, state, road } = response.data.results[0].components;
            // setLocationInfo({ country, county });
            // setError(null);

            register({
                userId: user?._id ?? '',
                page: page,
                country: country,
                state: state,
                city: county,
                road: road,
                formatted: response.data.results[0].formatted,
                lat: latitude,
                lon: longitude,
                operator: '',
                device: navigator.userAgent,
                ip: ''
            })

        } catch (error) {
            // console.log(error)
            // setError('Não foi possível obter as informações da localização.');

            register({
                userId: user?._id ?? '',
                page: page,
                country: 'erro ao pegar pais',
                state: 'erro ao pegar provincia',
                city: 'erro ao pegar cidade',
                road: 'erro ao pegar bairro',
                formatted: '',
                lat: latitude,
                lon: longitude,
                operator: '',
                device: navigator.userAgent,
                ip: ''
            })

        } finally {
            // setLoading(false);
        }
    };


    return (<></>)
    // (
    //     <div>
    //         {location.latitude && location.longitude && (
    //             <div>
    //                 <h3>Minha Localização:</h3>
    //                 <p>Latitude: {location.latitude}</p>
    //                 <p>Longitude: {location.longitude}</p>
    //                 <h3>Informações da Localização:</h3>
    //                 <p>País: {locationInfo.country}</p>
    //                 <p>Cidade: {locationInfo.city}</p>
    //             </div>
    //         )}
    //         {error && <p>Erro: {error}</p>}
    //     </div>
    // )
}
