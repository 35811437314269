// TEMPLATE ADMINISTRAVO PRINCIPAL 
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import AppsIcon from '@material-ui/icons/Apps';
import { Box } from '@material-ui/core';
import CachedIcon from '@mui/icons-material/Cached';
import { useSelector } from 'react-redux';
import PesquisarEstabelecimento from './pesquisarEstabelecimento';
import AvatarAdmin from './avatarAdmin';
import CustonLoadingAremedGEstor from '../../load/custonLoadingAremedGEstor';
import MessageState from '../../messages/messageState';

// detector de tipo de dispositivo
// import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { isDesktop } from 'react-device-detect';

import ShortNome from '../../utils/ShortNome';
import NavLateralDlif from '../departamentos/dlif/navLateralDlif';
import Loading from '../../load/loading';
import NavLateralDmts from '../departamentos/dmts/navLateralDmts';
import { useLocation } from 'react-router-dom';
import NavLateralMaster from '../departamentos/master/navLateralMaster';


const drawerWidth = 275;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    rootAcordiao: {
        width: '100%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        position: 'relative',
        // height: '100vh'
        // zIndex: 1,
    },
    drawerPaper: {
        width: drawerWidth,
        background: '#85287e',
        color: '#fff',

        // [theme.breakpoints.down('xs')]: {
        //     zIndex: 1
        // },

        '&::-webkit-scrollbar': {
            width: '3px'
        },
        ' &::-webkit-scrollbar-thumb': {
            backgroundColor: '#000',
            // borderRadius: '10px',
        },
        ' &::-webkit-scrollbar-track': {
            backgroundColor: '#fff'
        }
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        // marginBottom: 40,

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        background: '#ebebf4',
        paddingTop: 0,
        minHeight: '100vh',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingTop: 0,
        marginLeft: 0,
        background: '#ebebf4',
        minHeight: '100vh',
    },

    img: {
        maxHeight: 55,
        width: 160,
        margin: 10,
    },

    icones: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 10,
    },

    icone: {
        margin: 7,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#b1b7ea',
            borderRadius: 3,
        },
    },

    titulo: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    // ACORDIAO ####################
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    hoverList: {
        transition: 'all .3s',
        '&:hover': {
            background: '#24274a',
        },
    },
    hoverListItem: {
        padding: 3,
        fontSize: 15,
        transition: 'all .3s',
        '&:hover': {
            // color: '#fff',
            backgroundColor: '#b1b7ea',
            borderRadius: 3,
            // fontSize: 20,
        },
    },
    ondeEstou: {
        backgroundColor: '#b1b7ea',
        borderRadius: 3,
    },

    // IMAGEM DE PERFIRL DE ADMIN LOGADO
    profile: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
    },
    info: {
        margin: '0 10px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }
}));


export default function TemplateAdmin({ element: component, ...rest }) {
    const url = useLocation();
    const user = useSelector(state => state.account.user);
    const departamento = useSelector(state => state.account.user?.departamento);
    // configView VARIAVEL QUE CONTROLA O MODO DE EXIBIÇÃO DO TEMPLATE ADMIN
    const configView = JSON.parse(localStorage.getItem("configView"))
    const [open, setOpen] = React.useState(configView ?? isDesktop);
    const classes = useStyles();
    const theme = useTheme();
    // console.log(user)

    const handleDrawerOpen = () => {
        localStorage.setItem("configView", JSON.stringify(true));
        setOpen(true);
    };

    const handleDrawerClose = () => {
        localStorage.setItem("configView", JSON.stringify(false));
        setOpen(false);
    };

    // const devDepartamento = process.env.DEPARTAMENTO ;
    // const isDevelopment = process.env.NODE_ENV === 'development';
    // console.log(isDevelopment)

    function getMenuLateralDepartamento(departamento) {


        switch (departamento) {
            case 'CNP':
                return <NavLateralMaster classes={classes} />;
            case 'DG':
                return <NavLateralMaster classes={classes} />;
            case 'DLIF':
                return <NavLateralDlif classes={classes} />;
            case 'DMTS':
                return <NavLateralDmts classes={classes} />;
            default:
                return <Loading text={'Departamento não identificado'} />;
        }

        // SEGUNDA ALTERNATIVA
        // if (url.pathname.includes('dlif')) {
        //     return <NavLateralDlif classes={classes} />;
        // } else if (url.pathname.includes('dmts')) {
        //     return <NavLateralDmts classes={classes} />;
        // } else {
        //     return <Loading />;
        // }
    }

    useEffect(() => {
        document.title = "GESTOR-ARMED";
        getMenuLateralDepartamento(departamento)
    }, [departamento, getMenuLateralDepartamento, url.pathname])

    return (
        <>
            {!user
                ?
                <CustonLoadingAremedGEstor open={true} />
                :
                <div className={classes.root}>
                    <CssBaseline />
                    {/* ################################## INICIO NavBar  ####################################### */}
                    <AppBar
                        position="fixed"

                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}

                        style={{ backgroundColor: '#fff', color: '#292b2b' }}
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, open && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>

                            <div className={classes.titulo} >
                                <Typography variant="h1" style={{ fontFamily: 'Work Sans', fontSize: 22, marginLeft: 15 }}>
                                    <strong> GESTOR - ARMED / {departamento}</strong>
                                </Typography>
                            </div>

                            <div className={classes.icones}>
                                <PesquisarEstabelecimento />
                                <CachedIcon titleAccess='Limpar cach' onClick={() => window.location.reload()} className={classes.icone} />
                                {/* <AppsIcon onClick={() => { navegate('/admin') }} titleAccess='Menu' className={classes.icone} /> */}
                                {/* <SettingsApplicationsOutlinedIcon onClick={() => { navegate('/admin/lista-de-usuarios-administradores') }} titleAccess='Definições' className={classes.icone} /> */}
                            </div>

                            <div className={classes.profile}>
                                <div className={classes.info}>
                                    <p><ShortNome nome={user?.dadosPessoais.nome} /> </p>
                                    <small>{user?.funcao}</small>
                                </div>

                                <div className={classes.profilePhoto}>
                                    <AvatarAdmin />
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {/* ################################## FIM NavBar  ####################################### */}

                    {/* ################################## INICIO NavLATERAL  ####################################### */}
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <Box className={classes.drawerHeader} style={{ background: '#fff', marginBottom: 20 }}>
                            <img src="/img/logo/logoArmed.svg" alt="CNP" height={44} className={classes.img} />
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon color='primary' /> : <ChevronRightIcon color='primary' />}
                            </IconButton>
                        </Box>

                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >

                            {/* SELECIONAR NAVLATERAL DE ACORDO AO DEPARTAMENTO */}
                            {getMenuLateralDepartamento(departamento)}

                        </List>
                    </Drawer>
                    {/* ################################## FIM  NavBar  ####################################### */}


                    {/* ##################################  INICIO CONTAINER  ####################################### */}
                    <main
                        // style={{ maxWidth: '100vw' }}
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <div className={classes.drawerHeader} />

                        {component}

                    </main>
                    {/* ##################################  FIM CONTAINER  ####################################### */}
                </div>
            }

            {/* MESSAGENS DE NOTIFICACOES */}
            <MessageState />
        </>
    );
}
