import { Instagram, LinkedIn } from "@material-ui/icons";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import { Link } from 'react-router-dom'
// import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

const useStyles = makeStyles({
    menuItens: {
        marginRight: 10,
        marginLeft: 10,
        textDecoration: 'none',
        color: '#85287e',
        transition: '.3s',
        '&:hover': {
            color: '#eaa925',
            // fontSize: 35
        },
    },
})

export default function RedesSociasFooter() {
    const classes = useStyles()

    return (
        <Box style={{ display: 'flex', alignItems: 'center', padding: 20 }}>

            <nav style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <a href={'https://www.facebook.com/ARMED.Angola/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                    <FacebookRoundedIcon />
                </a>

                <a href={'https://www.instagram.com/armed_angola/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                    <Instagram />
                </a>

                <a href={'https://www.linkedin.com/company/96306438/admin/feed/posts/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                    <LinkedIn />
                </a>
            </nav>

            {/* <img src="/img/linksUteis/divider.svg" alt="" /> */}
            <div style={{ padding: 10, color: '#85287e', fontSize: 13, lineHeight: 1.5, borderLeft: '2px solid #85287e' }}>
                <p>Tel. (+244) 945 817 227</p>
                <p>expediente@armed.gov.ao | www.armed.gov.ao</p>
                <p>Rua Cmdt. Che-Guevara N° 86/86A,</p>
                <p>Maculusso, Luanda - Angola</p>
                <Link to={'/politicas-e-servicos'} className={classes.menuItens} style={{ marginLeft: 0 }}> <b>Regras, politicas e serviços</b></Link>
            </div>
        </Box>
    )
}