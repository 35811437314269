import { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const GraficoHistoricoSecond = ({data, labels}) => {

  const [state, setState] = useState({

    series: [{
      data: data || []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 300
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: labels || [],
      },
  
    },
  });

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      series: [{
        data: data || []
      }],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: labels || []
        }
      }
    }));
  }, [data, labels]);

    return (
      <div>
        <Chart
          options={state.options}
          series={state.series}
          type="bar"
          height={1015}
        />
      </div>
    );
}

export default GraficoHistoricoSecond;